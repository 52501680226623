import * as React from 'react'
import { List, Datagrid, TextField, ArrayField, SingleFieldList, ChipField, RichTextField, EmailField, DateField, Show, ReferenceManyField, TabbedShowLayout, Tab } from 'react-admin'
import { Filter, SearchInput, useNotify, useRedirect, useMutation } from 'react-admin';
import { makeStyles, Chip, Grid, Box, Typography, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ label }) => {
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={label} />;
};
const UsersFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <QuickFilter source="administrador" label="Admininstradores" defaultValue={true} />
        <QuickFilter source="acreditado" label="Acreditados" defaultValue={true} />
        <QuickFilter source="status" label="Desativados" defaultValue={'inativo'} />
    </Filter>
);

export const UserList = props => (
    <List {...props} title="Usuários" filters={ <UsersFilter />} >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="nome" />
            <EmailField source="email" />
            <TextField source="status" />
            <ArrayField
                label="Perfis"
                source="UserRoles"
            >
                <SingleFieldList linkType="null">
                    <ChipField source="Role.nome" />
                </SingleFieldList>
            </ArrayField>
            <DateField source="updatedAt" />
            <TextField source="deletedAt" />
        </Datagrid>
    </List>
);

const ComentarioPanel = ({ id, record, resource }) => (
    <div dangerouslySetInnerHTML={{ __html: record.Comentario.texto }} />
);

const CustomField = ({value, label}) => (
    <Box className="ra-field" style={ { marginTop: '8px', marginBottom: '4px'} }>
        <div>
            <Typography variant="subtitle1" >{label}</Typography>
        </div>
        <div>
        <Typography variant="body2" gutterBottom>{value}</Typography>
        </div>
    </Box>
)
const ShowPerfil = ({ record }) => (
    <Grid container>
        <Grid item sm={6}>
            <CustomField label="Id:" value={record.id} />
            <CustomField label="Nome:" value={record.nome} />
            <CustomField label="Pseudonimo:" value={record.pseudonimo} />
            <CustomField label="Status:" value={record.status} />
            <CustomField label="Criado Em:" value={new Date(record.createdAt).toString()} />
            <CustomField label="Atualizado Em:" value={new Date(record.updatedAt).toString()} />
        </Grid>
        <Grid item sm={6}>
            <div><img src={record.foto} alt={record.nome}/></div>
            <CustomField label="Bio:" value={record.bio} />
            <CustomField label="Sobre:" value={record.sobre} />
            <CustomField label="Telefone:" value={record.telefone} />
            <CustomField label="E-mail:" value={record.email} />
        </Grid>
    </Grid>
);

const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        color: '#3f51b5',
        '& svg': { color: '#3f51b5' }
    },
});


const AdicionarAdminButton = ({userId}) => {
    const classes = useStyles();
    const notify = useNotify();
    const redirect = useRedirect();
    
    const [adicionarAdmin, { loading }] = useMutation(
        {
            type: 'create',
            resource: 'assign',
            payload: { data: { userId, roleId: 1 } },
        },
        {
            onSuccess: ({ data }) => {
                redirect('/users');
                notify('Perfil adicionado');
            },
            onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
        }
    );
    return <Button
        onClick={adicionarAdmin}
        className={classes.button}
        disabled={loading}
    >
        <AddIcon /> Adicionar Admin
    </Button>
}

const RemoverAdminButton = ({id}) => {
    const classes = useStyles();
    const notify = useNotify();
    const redirect = useRedirect();

    const [removerAdmin, { loading }] = useMutation(
        {
            type: 'delete',
            resource: 'assign',
            payload: { id: id },
        },
        {
            onSuccess: ({ data }) => {
                redirect('/users');
                notify('Perfil removido');
            },
            onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
        }
    );

    return <Button
        onClick={removerAdmin}
        className={classes.button}
        disabled={loading}
    >
        <RemoveIcon /> Remover Admin
    </Button>
}

const AdicionarAcreditadoButton = ({userId}) => {
    const classes = useStyles();
    const notify = useNotify();
    const redirect = useRedirect();

    const [adicionarAcreditado, { loading }] = useMutation(
        {
            type: 'create',
            resource: 'assign',
            payload: { data: { userId, roleId: 2 } },
        },
        {
            onSuccess: ({ data }) => {
                redirect('/users');
                notify('Perfil adicionado');
            },
            onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
        }
    );

    return <Button
        onClick={adicionarAcreditado}
        className={classes.button}
        disabled={loading}
    >
        <AddIcon /> Adicionar Acreditado
    </Button>
}

const RemoverAcreditadoButton = ({id}) => {
    const classes = useStyles();
    const notify = useNotify();
    const redirect = useRedirect();

    const [removerAcreditado, { loading }] = useMutation(
        {
            type: 'delete',
            resource: 'assign',
            payload: { id: id },
        },
        {
            onSuccess: ({ data }) => {
                redirect('/users');
                notify('Perfil removido');
            },
            onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
        }
    );
    return <Button
        onClick={removerAcreditado}
        className={classes.button}
        disabled={loading}
    >
        <RemoveIcon /> Remover Acreditado
    </Button>
}

const SetRole = ({record}) => {
    const isMaster = record.email === 'zeuslp@legispedia.com.br';
    const adminIndex = record.UserRoles.findIndex(i => i.Role.nome === 'Administrador');
    const acreditadoIndex = record.UserRoles.findIndex(i => i.Role.nome === 'Acreditado');
    if (!isMaster) {
        return (
            <div>
                {adminIndex !== -1 ? <RemoverAdminButton id={record.UserRoles[adminIndex].id} /> : <AdicionarAdminButton userId={record.id} />}
                {acreditadoIndex !== -1 ? <RemoverAcreditadoButton id={record.UserRoles[acreditadoIndex].id} /> : <AdicionarAcreditadoButton userId={record.id} />}
            </div>
        )
    } else {
        return '';
    }
}

export const UsersShow = (props) => (
    <Show {...props}>

        <TabbedShowLayout>
            <Tab label="Detalhes">
                <ShowPerfil />
                <ArrayField
                    label="Perfis"
                    source="UserRoles"
                >
                    <SingleFieldList linkType="null">
                        <ChipField source="Role.nome" />
                    </SingleFieldList>
                </ArrayField>
                <SetRole />
            </Tab>
            <Tab label="Comentários" path="comentarios">
                <ReferenceManyField reference="comentarios" target="userId" addLabel={false}>
                    <Datagrid>
                        <TextField source="id" />
                        <TextField source="codigo" />
                        <RichTextField source="texto" />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label="Solicitações" path="solicitacoes">
                <ReferenceManyField reference="solicitacao" target="userId" addLabel={false}>
                    <Datagrid>
                        <TextField source="id" />
                        <DateField source="createdAt" />
                        <DateField source="updatedAt" />
                        <TextField source="status" />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label="Denúncias" path="denuncias">
                <ReferenceManyField reference="denuncia" target="userId" addLabel={false}>
                    <Datagrid expand={<ComentarioPanel />}>
                        <TextField source="id" />
                        <TextField source="motivo" />
                        <DateField source="createdAt" />
                        <DateField source="updatedAt" />
                        <TextField source="status" />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);