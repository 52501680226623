import { UserProfile, UserProfileActionTypes, REFRESH_PROFILE, SET_TOKEN, SET_FOTO, SET_ROLES } from '../types/userProfile';

export const userProfileReducerDefaultState: UserProfile = { token: '', roles: [], id: 0, nome: '', email: '' };

const userProfileReducer = (state = userProfileReducerDefaultState, action: UserProfileActionTypes) : UserProfile => {
    switch(action.type) {
        case REFRESH_PROFILE : 
            return { 
                ...state,
                id: action.payload.id,
                nome: action.payload.nome,
                email: action.payload.email,
                telefone: action.payload.telefone,
                bio: action.payload.bio,
                sobre: action.payload.sobre,
                foto: action.payload.foto,
                pseudonimo: action.payload.pseudonimo
            }
        case SET_TOKEN : 
            return {
                ...state,
                token: action.payload
            }
        case SET_FOTO : 
            return {
                ...state,
                foto: action.payload
            }
        case SET_ROLES : 
            return {
                ...state,
                roles: action.payload
            }
        default: return state;
    }
}

export { userProfileReducer };