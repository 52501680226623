import { ComentariosActionTypes, ComnetariosState, FETCH_LIKES, ADD_LIKE, REMOVE_LIKE } from '../types/comentarios';

const comentariosReducerDefaultState: ComnetariosState = { liked: [] };

const comentariosReducer = (state = comentariosReducerDefaultState, action: ComentariosActionTypes) : ComnetariosState => {
    switch(action.type) {
        case FETCH_LIKES :
            return { liked: action.payload };
        case ADD_LIKE : 
            return { 
                ...state,
                liked:[...state.liked, action.payload]
            }
        case REMOVE_LIKE : 
            return {
                ...state,
                liked: state.liked.filter(item => item !== action.payload )
            }
        default: return state;
    }
}

export { comentariosReducer };