import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
// import { useTranslate } from 'react-admin';
// import { Customer, Order } from '../types';

interface Props {
    usuarios?: []
}

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },
}));

const UsuariosBox: FC<Props> = ({ usuarios = [] }) => {
    const classes = useStyles();
    // const translate = useTranslate();
    console.log(usuarios)
    return (
        <Card className={classes.root}>
            <CardHeader title={'Novos usuários'} />
            <List dense={true}>
                {usuarios.map((item: any, index: number) => (
                    <ListItem
                        key={item.id}
                        button
                        component={Link}
                        to={`/users/${item.id}/show`}
                    >
                        <ListItemAvatar>
                            <Avatar
                                src={`${
                                    item.foto
                                }?size=32x32`}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={item.nome}
                            secondary={item.bio}
                        />
                        {/* <ListItemSecondaryAction>
                            <span className={classes.cost}>
                                {item.total}$
                            </span>
                        </ListItemSecondaryAction> */}
                    </ListItem>
                ))}
            </List>
        </Card>
    );
};

export default UsuariosBox;