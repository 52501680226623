import React, { Component } from "react";
import { Grid, Box, Container, CircularProgress } from "@material-ui/core";
import "./legislacao.scss";
import { Link } from "react-router-dom";
import { withSnackbar, WithSnackbarProps } from 'notistack';
import API from "../../API";
import { environment } from '../../config/environment';
import { Helmet } from "react-helmet";


type MyProps = WithSnackbarProps;
type MyState = { legislacaoList: any };
class LegislacaoListPage extends Component<MyProps, MyState> {
  constructor(props: Readonly<MyProps>) {
    super(props);
    this.state = {
        legislacaoList: undefined
    };
  }

  async componentDidMount() {
    try {
        let request = await API.get('/legislacao/ultimas/30');
        this.setState({legislacaoList: request.data.lesgislacoes}) 
    }
    catch(e)
    {
        if(e.response) {
            e.response.data.errors.forEach((element: any) => {
                this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
            });
        }
        else {
            this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
        }
    }
  }

  render() {
    if(this.state.legislacaoList === undefined)
      return <div className="legislacao-loading"><CircularProgress /></div>;
    return (
      <Container fixed className="container-legislacao-list">
        <Helmet>
          <title>Legislação - Legispedia</title>
          <meta name="description" content="Legislação atualizada conforme o Planalto, planalto.gov.br" />
        </Helmet>

        <h1 className="title color-dark-gray">Legislação</h1>
        <Grid
          container
          direction="row"
          alignItems="center"
          wrap="wrap"
          className={"legislacao-list"}>
          { this.state.legislacaoList.map((item: any, index: number) => (
                <Box key={item.id} className="legislacao-item color-bright-gray">
                  <Link to={"/legislacao/" + item.id} className="color-weak-green title-slab">
                    {item.nome}
                    <span className="color-bright-gray text-default">{item.texto}</span>
                  </Link>
                </Box>
              ))}
        </Grid>
      </Container>
    );
  }
}

export default withSnackbar(LegislacaoListPage)