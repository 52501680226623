export const environment = {
  serverUrl: process.env.REACT_APP_BACKEND_URL || 'https://api.legispedia.com.br',
  facebook: {
      appId: '256813602279671'
  },
  google: {
      appId: '693746985475-ksar5h6jc58fqsql9m5jjqum6ubhjubd.apps.googleusercontent.com'
  },
  connectionErrorMsg: 'Erro de conexão com o servidor. Tente novamente mais tarde.'
}
