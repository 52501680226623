export const FETCH_LIKES = "FETCH_LIKES"
export const ADD_LIKE = "ADD_LIKE"
export const REMOVE_LIKE = "REMOVE_LIKE"

export interface FetchLikesAction {
    type: typeof FETCH_LIKES
    payload: number[]
}
export interface addLikeAction {
    type: typeof ADD_LIKE
    payload: number
}
export interface removeLikeAction {
    type: typeof REMOVE_LIKE
    payload: number
}

export interface ComnetariosState {
    liked: number[]
}

export type ComentariosActionTypes = FetchLikesAction | addLikeAction | removeLikeAction;