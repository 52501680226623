import { IsLoggedActionTypes } from '../types/isLogged';

const loggedReducerDefaultState: boolean = false;

const isLoggedReducer = (state = loggedReducerDefaultState, action: IsLoggedActionTypes) : boolean => {
    switch(action.type) {
        case 'SIGN_IN' :
            return true;
        case 'SIGN_OUT' :
            return false;
        default: return state;
    }
}

export { isLoggedReducer };