import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import logo from "../../assets/icons/logo-big.svg";
import API from "../../API";
import { ConnectedProps, connect } from "react-redux";
import { AppState } from "store/reducers";
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { environment } from "config/environment";
import {Helmet} from "react-helmet";
  
  const mapState = (state: AppState) => ({
    isLogged: state.isLogged
  })
  
  const mapDispatch = {

  }
  
  const connector = connect(mapState, mapDispatch)
  type PropsFromRedux = ConnectedProps<typeof connector>
  
  type RouterProps = RouteComponentProps<{}>
  
  type Props = PropsFromRedux & WithSnackbarProps & RouterProps & {
    hover?: boolean;
  }
  
  type MyState = {
    username: string;
    loading: boolean;
  };
  
  class RecuperarSenhaPage extends Component<Props, MyState> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = { username: "", loading: false};
        if(this.props.isLogged)
            this.props.history.push("/");

        this.handleRecuperarSenha = this.handleRecuperarSenha.bind(this);
    }

    componentDidMount() {
        document.body.classList.add("bg-light-gray");
    }

    async handleRecuperarSenha(e: any) {
        e.preventDefault();
        try {
            this.setState({ loading: true });
            await API.post('/email/recover', { email: this.state.username});
            this.props.enqueueSnackbar('E-mail de recuperação de senha enviado com sucesso.', { variant: 'success' });
            this.props.history.push('/');
        } catch (e) {
            if(e.response) {
                e.response.data.errors.forEach((element: any) => {
                    this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
                });
              }
              else {
                this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
              }
        }
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Recuperar Senha - Legispedia</title>
                    <meta name="description" content="Uma ferramenta em constante construção, criada para expandir a capacidade dos usuários em lidar com a complexidade do sistema jurídico brasileiro." />
                </Helmet>
                <Container fixed>
                    <Grid container>
                        <Grid item xs={12}>
                            <img src={logo} alt="Legispedia" className="login-logo" />
                            <div className="login-box input-margin">
                                <h1 className="login-title">Recuperar Senha</h1>

                                <form name="form">
                                    <input type="email" placeholder="Digite seu e-mail" className="input" value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} />                                    
                                    <input type="submit" className="input btn-submit bg-weak-green borderless color-white" value="ENVIAR" onClick={(e) => this.handleRecuperarSenha(e)} disabled={this.state.loading}/>
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    }
  }

  export default withSnackbar(withRouter(connector(RecuperarSenhaPage)))