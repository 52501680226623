import * as React from "react";
import { List, Datagrid, ReferenceField, DateField, TextField, Button, useMutation } from 'react-admin'
import { Filter } from 'react-admin';
import { makeStyles, Chip } from '@material-ui/core';

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ label }) => {
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={label} />;
};
const SolicitacoesFilter = props => (
    <Filter {...props}>
        <QuickFilter source="aprovadas" label="Aprovadas" defaultValue={true} />
        <QuickFilter source="rejeitadas" label="Rejeitadas" defaultValue={true} />
        <QuickFilter source="status" label="Pendente" defaultValue={'Pendente'} />
    </Filter>
);

const ApproveButton = ({ record }) => {
    const [approve, { loading }] = useMutation({
        type: 'update',
        resource: 'solicitacao',
        payload: { id: record.id, data: { userId: record.userId, status: 'Aprovada' } }
    });
    
    if(record.status !== 'Pendente')
        return null;

    return <Button label="Aprovar" redirect="list" onClick={approve} disabled={loading} />;
};

const RejectButton = ({ record }) => {
    const [approve, { loading }] = useMutation({
        type: 'update',
        resource: 'solicitacao',
        payload: { id: record.id, data: { status: 'Rejeitada' } }
    });

    if(record.status !== 'Pendente')
        return null;
        
    return <Button label="Rejeitar" redirect="list" onClick={approve} disabled={loading} />;
};

export const SolicitacaoList = props => (
    <List {...props} filters={<SolicitacoesFilter />} filterDefaultValues={{ status: 'Pendente' }} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid>
            <TextField source="motivo" label="Motivo" />
            <ReferenceField label="Usuário" source="userId" reference="users"><TextField source="nome" /></ReferenceField>
            <DateField label="Criado em" source="createdAt" />
            <DateField label="Atualizado em" source="updatedAt" />
            <TextField source="status" />
            <ApproveButton />
            <RejectButton />
        </Datagrid>
    </List>
);