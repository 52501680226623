import React, { Component, lazy, Suspense } from "react";
import logo from "../../assets/icons/logo-big.svg";
import './login.scss';
import './criar-conta-page.scss';
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { UserProfile, REFRESH_PROFILE, SET_TOKEN, SET_ROLES } from "store/types/userProfile";
import API from "API";
import { ConnectedProps, connect } from "react-redux";
import { AppState } from "store/reducers";
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { environment } from '../../config/environment';
import { Info } from 'react-feather';
import { Helmet } from "react-helmet";

const LazyPasswordStrengthBar = lazy(() => import('react-password-strength-bar') );
const renderLoader = () => <p>&nbsp;</p>;

const mapState = (state: AppState) => ({ })

  const mapDispatch = {
    signIn: () => ({ type: 'SIGN_IN' }),
    fetchLikes: (array: number[]) => ({type: 'FETCH_LIKES', payload: array}),
    refreshProfile: (profile: UserProfile) => ({ type: REFRESH_PROFILE, payload: profile }),
    setToken: (token: string) => ({type: SET_TOKEN, payload: token}),
    setRoles: (roles: string[]) => ({type: SET_ROLES, payload: roles})
  }
  
  const connector = connect(mapState, mapDispatch)
  type PropsFromRedux = ConnectedProps<typeof connector>
  
  type RouterProps = RouteComponentProps<{}>
  
  type Props = PropsFromRedux & WithSnackbarProps & RouterProps

  type MyState = {
    pseudonimo: string;
    nome: string;
	email: string;
	celular: string;
	senha: string;
	confirmarsenha: string;
    loading: boolean;
    nomeInvalido: boolean;
    pseudonimoInvalido: boolean;
    emailInvalido: boolean;
    telefoneInvalido: boolean;
    senhaInvalido: boolean;
    confirmarSenhaInvalido: boolean;
    termosInvalido: boolean;
  };

class CriarContaPage extends Component<Props, MyState>{

	constructor(props: Readonly<Props>) {
        super(props);
        this.state = { 
        	pseudonimo: "",
        	nome: "",
        	email: "",
        	celular: "",
        	senha: "",
        	confirmarsenha: "",
        	loading: false,
        	pseudonimoInvalido: false,
        	nomeInvalido: false,
        	emailInvalido: false,
		    telefoneInvalido: false,
		    senhaInvalido: false,
		    confirmarSenhaInvalido: false,
		    termosInvalido: false,
        };
	}

	handleNomeInvalido (e: any) {
	  const nome = this.state.nome;

	  if(nome.length > 3){
	  	this.setState({nomeInvalido: false});
	  }else{
	  	this.setState({nomeInvalido: true});
	  }
	}

	handleEmailInvalido (e: any) {
	  const email = this.state.email;
	  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	  if(re.test(String(email).toLowerCase())){
	  	this.setState({emailInvalido: false});
	  }else{
	  	this.setState({emailInvalido: true});
	  }
	}
	
	handleTelefoneInvalido (e: any) {
	  let telefone = this.state.celular;
	  let resultado = "";

	  let numbers =  telefone.match(/\d/g);
	  let numberLength = 0;

	  if (numbers) {
	    numberLength = numbers.join("").length;
	  }
	  console.log(numberLength);
	  telefone = telefone.replace(/\D/g, '');
	  telefone = telefone.replace(/(\d{1})(\d)/, '($1$2');
	  telefone = telefone.replace(/(\d{2})(\d)/, '$1) $2');
	 

	  if (numberLength > 10) {
		   telefone = telefone.replace(/(\d{5})(\d{1,2})/, '$1-$2');

	  } else {
	 	 telefone = telefone.replace(/(\d{4})(\d{1,2})/, '$1-$2');
	  }

	  resultado = telefone;

	  console.log(telefone);
	  this.setState({ celular: resultado })
	}

	cellphoneMask(userInput: any) {
	    let numbers =  userInput.match(/\d/g);
	    let numberLength = 0;
	    if (numbers) {
	      numberLength = numbers.join("").length;
	    }
	    console.log(numberLength);

	    if (numberLength > 10) {
	    	return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/ , /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
	    } else {
	     	return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
	    }
	}

	handleSenhaInvalido (e: any) {
	  const senha = this.state.senha;
	  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

	  if(re.test(senha)){
	  	this.setState({senhaInvalido: false});
	  }else{
	  	this.setState({senhaInvalido: true});
	  }
	}

	handleConfirmarSenhaInvalido (e: any) {
	  const senha = this.state.senha;
	  const confirmarsenha = this.state.confirmarsenha;

	  if(confirmarsenha === senha){
	  	this.setState({confirmarSenhaInvalido: false});
	  }else{
	  	this.setState({confirmarSenhaInvalido: true});
	  }
	}

	handlePseudonimoInvalido (e: any) {
	  const pseudonimo = this.state.pseudonimo;

	  if(pseudonimo.length > 3){
	  	this.setState({pseudonimoInvalido: false});
	  }else{
	  	this.setState({pseudonimoInvalido: true});
	  }
	}

	componentDidMount() {
		document.body.classList.add("bg-light-gray");
	}

	async handleSubmit(e: any) {
		e.preventDefault();

		this.setState({ loading: true });
        const { nome, email, celular, senha } = this.state;

		if(this.state.senha !== this.state.confirmarsenha) {
			this.props.enqueueSnackbar('As senhas não conferem.', { variant: 'error' }) 
	        this.setState({ loading: false });	
		} else {
			if(this.state.termosInvalido === false){
	
				this.props.enqueueSnackbar('Você precisa aceitar os termos.', { variant: 'error' }) 
				this.setState({ loading: false });	
				
			}else{
	
				try {
					await API.post('/register', {
						"nome": nome,
						"email": email,
						"celular": celular,
						"senha": senha
					});
	
					this.props.history.push("/aguardando-confirmacao");
				} catch (e) {
					if(e.response) {
						e.response.data.errors.forEach((element: any) => {
							this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
						});
						this.setState({ loading: false });	
					}
					else {
					  this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
					}
				}        	
			}
		}
	}

    render() {
        return (
            <div>
			<Helmet>
				<title>Crie sua conta - Legispedia</title>
				<meta name="description" content="Uma ferramenta em constante construção, criada para expandir a capacidade dos usuários em lidar com a complexidade do sistema jurídico brasileiro." />
			</Helmet>

		      <Container fixed>
		        <Grid container>
		          <Grid item sm={12} md={12} className="w-100-mobile">
		            <img src={logo} alt="Legispedia" className="login-logo"/>

		            <div className="login-box input-margin">
		            	<h1 className="login-title">Crie sua conta</h1>

			            <button className="input login-with google">
		            		<i className="icon icon-google"></i>
		            		Continuar com Google
		            	</button>

		            	<button className="input login-with facebook">
		            		<i className="icon icon-facebook"></i>
		            		Continuar com Facebook
		            	</button>

		            	<span className="login-separator">Ou</span>

		            	<form name="form" onSubmit={(e) => this.handleSubmit(e)} autoComplete="off">
		            		{/*<div className={"item-form " + (this.state.pseudonimoInvalido ? " invalid": " ")}>
			            		<input type="text" placeholder="Nome de usuário" className="input" value={this.state.pseudonimo} onChange={(e) => this.setState({ pseudonimo: e.target.value })} onKeyUp={(event) => this.handlePseudonimoInvalido(event)} />
			            		<p className="message">O nome de usuário é muito curto.</p>
		            			<i className="icon-info">
		            				<Info />
		            				<div className="note-info">Seu nome de usuário será público</div>
		            			</i>
		            		</div>*/}
		            		<div className={"item-form " + (this.state.nomeInvalido ? " invalid": " ")}>
			            		<input type="text" placeholder="Nome completo" className="input" value={this.state.nome} onChange={(e) => this.setState({ nome: e.target.value })} onKeyUp={(event) => this.handleNomeInvalido(event)} />
			            		<p className="message">O nome é muito curto.</p>
		            			<i className="icon-info">
		            				<Info />
		            				<div className="note-info">Apos o cadastro, você pode denifir um 'nome de usuário' nas configurações, para que seu nome completo não apareça para outros usuários.</div>
		            			</i>
		            		</div>
		            		<div className={"item-form " + (this.state.emailInvalido ? " invalid": " ")}>
		            			<input type="email" placeholder="Email" className="input" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} onKeyUp={(event) => this.handleEmailInvalido(event)} />
		            			<p className="message">E-mail inválido.</p>
		            		</div>
		            		<div className={"item-form " + (this.state.telefoneInvalido ? " invalid": " ")}>
		            			<input type="tel" maxLength={15} placeholder="Celular" className="input" value={this.state.celular} onChange={(e) => this.setState({ celular: e.target.value })} onKeyUp={(event) => this.handleTelefoneInvalido(event)} />
		            			<p className="message">Telefone Inválido.</p>
		            		</div>
							<div className={"item-form " + (this.state.senhaInvalido ? " invalid": " ")}>
		            			<input type="password" placeholder="Senha" className="input" value={this.state.senha} onChange={(e) => this.setState({ senha: e.target.value })} onKeyUp={(event) => this.handleSenhaInvalido(event)} />
								<span className="forca-senha">{ this.state.senha !== '' ? <Suspense fallback={renderLoader()}><LazyPasswordStrengthBar password={this.state.senha} scoreWords={['fraco', 'fraco', 'ok', 'bom', 'forte']} minLength={8} shortScoreWord="muito curta"/></Suspense>: '' }</span>
		            			<p className="message">A senha deve conter pelo menos 8 caracteres, ao menos 1 maiúsculo, 1 minúsculo e 1 dígito.</p>
		            			<i className="icon-info">
		            				<Info />
		            				<div className="note-info">A senha deve conter pelo menos 8 caracteres, ao menos 1 maiúsculo, 1 minúsculo e 1 dígito.</div>
		            			</i>
		            		</div>
		            		<div className={"item-form " + (this.state.confirmarSenhaInvalido ? " invalid": " ")}>
		            			<input type="password" placeholder="Confirmar Senha" className="input" value={this.state.confirmarsenha} onChange={(e) => this.setState({ confirmarsenha: e.target.value })} onKeyUp={(event) => this.handleConfirmarSenhaInvalido(event)}/>
		            			<p className="message">As senhas não conferem.</p>
		            		</div>
		            		<div className="terms-accept">
		            			<input type="checkbox" onClick={() => this.setState({ termosInvalido: !this.state.termosInvalido })}/> <span className="color-bright-gray text-default">Li e concordo com os <Link to="/termos" className="link-green d-inline-block">termos de uso</Link> e a <Link to="/politica-de-privacidade" className="link-green d-inline-block">política de privacidade</Link></span>
		            		</div>

		            		<input type="submit" className={"input bg-weak-green borderless color-white submit" + (this.state.loading ? " btn-disabled" : "")} value="CRIAR CONTA"/>
		            	</form>
						<Link to="/login" className="text-small link-green celular-after link-criar-conta">Já tem conta? <strong>Faça login</strong></Link>
		            </div>	
		          </Grid>
		        </Grid>
		      </Container>
            </div>
        )
    }
}

export default withSnackbar(withRouter(connector(CriarContaPage)))