export const REFRESH_PROFILE = "REFRESH_PROFILE"
export const SET_TOKEN = "SET_TOKEN"
export const SET_FOTO = "SET_FOTO"
export const SET_ROLES = "SET_ROLES"

export interface refreshProfileAction {
    type: typeof REFRESH_PROFILE;
    payload: UserProfile
}

export interface setTokenAction {
    type: typeof SET_TOKEN;
    payload: string
}

export interface setFotoAction {
    type: typeof SET_FOTO;
    payload: string
}

export interface setRolesAction {
    type: typeof SET_ROLES;
    payload: string[]
}

export interface UserProfile {
    token: string;
    roles: string[];
    id: number;
    nome: string;
    email: string;
    telefone?: string;
    bio?: string;
    sobre?: string;
    foto?: string;
    pseudonimo?: string;
}

export type UserProfileActionTypes = refreshProfileAction | setTokenAction | setFotoAction | setRolesAction;