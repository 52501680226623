import React, { Component } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import "./legislacao.scss";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import API from "../../API";
import * as IconFeather from 'react-feather';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { environment } from '../../config/environment';
import * as queryString from 'query-string';
 

type RouterProps = RouteComponentProps<{}>
type MyProps = WithSnackbarProps & RouterProps;
type MyState = { query: string, searchResults: any };
class LegislacaoResultadosBuscaPage extends Component<MyProps, MyState> {
  constructor(props: Readonly<MyProps>) {
    super(props);
    this.state = {
        query: '',
        searchResults: undefined
    };
    this.handleClickBusca = this.handleClickBusca.bind(this);
    this.handleBusca = this.handleBusca.bind(this);
  }

  componentDidMount() {
    if (this.props.match) {
        const qs = queryString.parse(this.props.history.location.search);

        if(qs.q === undefined) {
            this.props.history.push('/');
        }else{
            this.handleBusca(qs.q as string)
        }
    } else {
        this.props.history.push('/');
    }
  }

  async handleBusca(query: string) {
    try {
      const request = await API.get('/search?q=' + query);
      this.setState({ query: query, searchResults: request.data.result});
    }
    catch(e)
    {
      if(e.response) {
          e.response.data.errors.forEach((element: any) => {
              this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
          });
      }
      else {
          this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
      }
    }
  }

  generateItemUrl(item: any) {
    if (item.rootId) {
        return `/legislacao/${item.rootId}/${item.id}`;
      } else {
        return `/legislacao/${item.id}`;
      }
  }

  handleClickBusca(item: any) {
    this.props.history.push(this.generateItemUrl(item));
  }

  render() {
    if (this.state.query === null || this.state.searchResults === undefined)
      return (
        <div className="legislacao-loading">
          <CircularProgress />
        </div>
      );

    return (
      <Grid container direction="row" alignItems="stretch" className="container-legislacao resultados-query">
        <Grid className={"left-panel"} item sm={12} md={4} container direction="column" alignItems="stretch">
          <Grid className="left-panel-content" container direction="column" alignItems="stretch" >
            <div className="header-resultados-query">
              <span className="back">
                <Link to="/">
                  <IconFeather.ArrowLeft />
                </Link>
              </span>
              <div className="input-search">
                <input type="text" value={this.state.query} onChange={(e) => this.setState({ query: e.target.value })} onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.handleBusca(this.state.query);
                    }
                  }}
                />
              </div>
              <span className="search-icon">
                <IconFeather.Search />
              </span>
            </div>

            <div className="list-resultados-query scroll">
              {this.state.searchResults.map((item: any, index: number) => (
                <div key={item.id} className="item-resultados-query" >
                  <Link to={this.generateItemUrl(item)}>
                    <h3 className="text-slab">
                        {item.leiPai ? (item.breadcrumb && item.breadcrumb !== '' ? `${item.leiPai} | ${item.breadcrumb.replace(/£/g, '|')}` : item.leiPai) : item.nome}
                    </h3>
                    <p className="text-default color-clear-gray">
                        {item.leiPai ? item.nome + "." : ""} {item.texto}
                    </p>
                    <span>
                        <IconFeather.ArrowRight />
                    </span>
                  </Link>
                    
                </div>
              ))}
            </div>
          </Grid>
          <div className="rodape footer-resultados-query"></div>
        </Grid>
        <Grid className={"right-panel"} item sm={12} md={8} container direction="column" alignItems="stretch" >
          <div className="footer-legislacao footer-legislacao-resultados-query"></div>
        </Grid>
      </Grid>
    );
  }
}

export default withSnackbar(withRouter(LegislacaoResultadosBuscaPage))