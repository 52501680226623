import React, {
    useState,
    useEffect,
    useCallback,
    FC,
    CSSProperties,
} from 'react';
import { useVersion, useDataProvider } from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';
import Welcome from './welcome';

// import Welcome from './Welcome';
// import MonthlyRevenue from './MonthlyRevenue';
// import NbNewOrders from './NbNewOrders';
// import PendingOrders from './PendingOrders';
// import PendingReviews from './PendingReviews';
// import NewCustomers from './NewCustomers';
import UsuariosBox from './UsuariosBox';
import ComentariosBox from './ComentariosBox';
// import { Customer, Order, Review } from '../types';

// interface OrderStats {
//     revenue: number;
//     nbNewOrders: number;
//     pendingOrders: Order[];
// }

// interface CustomerData {
//     [key: string]: Customer;
// }

interface State {
    comentarios?: [];
    usuarios?: [];
}

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const Dashboard: FC = () => {
    const [state, setState] = useState<State>({});
    const version = useVersion();
    const dataProvider = useDataProvider();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    /*
    TO DO
    Realizar validação no dataprovider pois ele sempre espera o fornecimento de sort e paginantion
    */
    const fetchComentarios = useCallback(async () => {

        const { data } = await dataProvider.getList('comentarios', {
            // filter: { date_gte: aMonthAgo.toISOString() },
            sort: { field: 'createdAt', order: 'DESC' },
            pagination: { page: 1, perPage: 10 },
        });

        setState(state => ({
            ...state,
            comentarios: data,
        }));
    }, [dataProvider]);

    /*
    TO DO
    Realizar validação no dataprovider pois ele sempre espera o fornecimento de sort e paginantion
    */
   const fetchUsuarios = useCallback(async () => {

        const { data } = await dataProvider.getList('users',{
            sort: { field: 'createdAt', order: 'DESC' },
            pagination: { page: 1, perPage: 10 },
        });

        setState(state => ({
            ...state,
            usuarios: data
        }));

    }, [dataProvider]);



    useEffect(() => {
        fetchComentarios();
        fetchUsuarios();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    const {
        comentarios,
        usuarios
    } = state;
    return isXSmall ? (
        <div>
            <div style={styles.flexColumn as CSSProperties}>
                <div style={{ marginBottom: '2em' }}>
                    <Welcome />
                </div>

                <div style={styles.flex}>
                    <UsuariosBox
                        usuarios={usuarios}
                    />
                </div>

                <div style={styles.singleCol}>
                    <ComentariosBox
                        comentarios={comentarios}
                    />
                </div>
            </div>
        </div>
    ) : isSmall ? (
        <div style={styles.flexColumn as CSSProperties}>
            <div style={styles.singleCol}>
                <Welcome />
            </div>
            
            <div style={styles.flex}>
                <UsuariosBox
                        usuarios={usuarios}
                    />
            </div>
            
            <div style={styles.singleCol}>
                <ComentariosBox
                        comentarios={comentarios}
                    />    
            </div>
        </div>
    ) : (
        <div style={styles.flex}>
            <div style={styles.leftCol}>
                <div style={styles.flex}>
                    <Welcome />
                </div>
                <div style={styles.singleCol}>
                    <UsuariosBox
                        usuarios={usuarios}
                    />
                </div>
            </div>
            <div style={styles.rightCol}>
                <div style={styles.flex}>
                    <ComentariosBox
                        comentarios={comentarios}
                    />

                </div>
            </div>
        </div>
    );
};

export default Dashboard;