import * as React from "react";
import { List, Datagrid, ReferenceField, DateField, TextField, SelectInput ,RichTextField, ReferenceInput } from 'react-admin'
import { Filter, SearchInput } from 'react-admin';



const ComentariosFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <ReferenceInput label="Legislação" source="leiPai_id" reference="legislacao">
            <SelectInput optionText="texto" />
        </ReferenceInput>
    </Filter>
);

const ComentarioPanel = ({ id, record, resource }) => (
    <div dangerouslySetInnerHTML={{ __html: record.texto }} />
);





export const ComentariosList = props => (
    <List {...props} filters={<ComentariosFilter />} sort={{ field: 'createdAt', order: 'ASC' }}>
        <Datagrid expand={<ComentarioPanel />} >
            <ReferenceField source="userId" link="show" label="Usuário" reference="users"><TextField source="nome" /></ReferenceField>
            <ReferenceField source="legislacaoId" label="Legislação" reference="legislacoes-itens" link={(record, reference) => `/legislacao/${record.legislacaoId}/edit/`}><TextField source="leiPai.nome"  /></ReferenceField>
            <ReferenceField source="legislacaoId" label="Breadcrumb" reference="legislacoes-itens" link={(record, reference) => `/legislacao/${record.legislacaoId}/edit/`}><TextField source="breadcrumb" /></ReferenceField>
            <ReferenceField source="legislacaoId" label="Item" reference="legislacoes-itens" link={(record, reference) => `/legislacao/${record.legislacaoId}/edit/`}><TextField source="nome" /></ReferenceField>
            <DateField source="createdAt" label="Criado em" locales="pt-BR" />
            <RichTextField source="texto" label="Texto" />
        </Datagrid>
    </List>
);