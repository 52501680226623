import { HomePage, LoginPage, NotFoundPage, LegislacaoPage, PerfilPage, CriarContaPage, AguardandoConfirmacaoPage, ReenviarConfirmacaoPage, ConfirmarPerfilPage, ConfiguracoesPerfilPage, TermosPage, AdminPage, RecuperarSenhaPage, RedefinirSenhaPage, LegislacaoResultadosBuscaPage, LegislacaoListPage } from './pages';


export type tabSelectedTypes = "default" | "text-view" | "summary";
export const Routes = [
  {
    path: "/",
    exact: true,
    class: "page-home",
    main: HomePage
  },
  {
    path: "/login",
    exact: true,
    class: "page-login",
    main: LoginPage
  },
  {
    path: "/recuperar-senha",
    exact: true,
    class: "recuperar-senha",
    main: RecuperarSenhaPage
  },
  {
    path: "/redefinir-senha",
    exact: true,
    class: "redefinir-senha",
    main: RedefinirSenhaPage
  },
  {
    path: "/legislacao/:id?/:item?/:comentarioId?",
    class: "page-legislacao",
    main: LegislacaoPage
  },
  {
    path: "/busca-legislacao",
    class: "page-legislacao",
    main: LegislacaoResultadosBuscaPage
  },
  {
    path: "/legislacao-lista",
    class: "page-legislacao",
    main: LegislacaoListPage
  },
  {
    path: "/perfil",
    class: "page-perfil",
    main: PerfilPage
  },
  {
    path: "/criar-conta",
    class: "page-criar-conta",
    main: CriarContaPage
  },
  {
    path: "/aguardando-confirmacao",
    class: "page-aguardando-confirmacao",
    main: AguardandoConfirmacaoPage
  },
  {
    path: '/reenviar-confirmacao',
    class: "page-aguardando-confirmacao",
    main: ReenviarConfirmacaoPage
  },
  {
    path: '/confirmar-cadastro',
    class: "page-aguardando-confirmacao",
    main: ConfirmarPerfilPage
  },
  {
    path: "/configuracoes-perfil",
    class: "page-configuracoes-perfil",
    main: ConfiguracoesPerfilPage
  },
  {
    path: "/termos",
    class: "page-termos",
    main: TermosPage
  },
  {
    path: "/politica-de-privacidade",
    class: "page-termos",
    main: TermosPage
  },
  {
    path: "/admin",
    class: "page-admin",
    main: AdminPage
  },  
  {
    path: "/404",
    exact: true,
    class: "page-not-found",
    main: NotFoundPage
  },
  {
    path: "*",
    class: "page-not-found",
    main: NotFoundPage
  },

];
