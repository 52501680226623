import React, { Component } from "react";
import { Container, CircularProgress } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './home.scss';
import moment from 'moment'
import 'moment/locale/pt-br';
import API from "API";
import './termos.scss';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { environment } from '../../config/environment';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";


type MyProps =  RouteComponentProps<{}> & WithSnackbarProps & {};  
type MyState = { termoVigente: any, politicaVigente: any, tabIndex: number };

class TermosPage extends Component<MyProps, MyState> {
    constructor(props: Readonly<MyProps>) {
      super(props);
      moment.locale('pt-br');
      this.state = { termoVigente: undefined, politicaVigente: undefined, tabIndex: 0 };
    }

    async componentDidMount(){
      try
      {
        let termo = await API.get('/termos-vigente');
        let politica = await API.get('/politica-vigente');
        const tabIndex = this.props.location.pathname.indexOf('politica-de-privacidade') !== -1 ? 1 : 0;
        this.setState({termoVigente: termo.data, politicaVigente: politica.data, tabIndex: tabIndex});
      }
      catch(e)
      {
        if(e.response) {
          e.response.data.errors.forEach((element: any) => {
              this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
          });
        }
        else {
          this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
        }
      }
    }

    render() {
      if(this.state.termoVigente === undefined || this.state.politicaVigente === undefined)
        return <div className="legislacao-loading"><CircularProgress /></div>;
      else
        return (
            <div>
              <Helmet>
                <title>Termos de Uso & Política de Privacidade - Legispedia</title>
                <meta name="description" content="Termos de Uso da Legispedia." />
              </Helmet>
              <Container fixed>
                <Grid container>
                  <Grid item sm={12} md={8}>

                    <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>
                      <TabList>
                        <Tab>Termos de Uso</Tab>
                        <Tab>Política de Privacidade</Tab>
                      </TabList>

                      <TabPanel>
                        <div className="header-termos">
                          {/* <h1 className="title-small">TERMOS DE USO LEGISPEDIA</h1> */}
                          <p className="termo-subtitle">Última modificação: { moment.parseZone(this.state.termoVigente.updatedAt).format('LL') }</p>
                        </div>

                        <div className="container-termos color-bright-gray text-normal" dangerouslySetInnerHTML={ {__html: this.state.termoVigente.texto} } ></div>
                      </TabPanel>

                      <TabPanel>
                        <div className="header-termos">
                          {/* <h1 className="title-small">POLÍTICA DE PRIVACIDADE LEGISPEDIA</h1> */}
                          <p className="termo-subtitle">Última modificação: { moment.parseZone(this.state.politicaVigente.updatedAt).format('LL') }</p>
                        </div>

                        <div className="container-termos color-bright-gray text-normal" dangerouslySetInnerHTML={ {__html: this.state.politicaVigente.texto} } ></div>
                      </TabPanel>
                    </Tabs>
                  </Grid>
                </Grid>
              </Container>
            </div>
        )
    }
}

export default withRouter(withSnackbar(TermosPage))
