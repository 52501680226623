import React, { Component } from "react";
import logo from '../../assets/icons/logo-big.svg';
import './login.scss';
import './aguardando-confirmacao-page.scss';
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

export default class AguardandoConfirmacaoPage extends Component{

	componentDidMount() {
		document.body.classList.add("bg-light-gray");
	}

    render() {
        return (
            <div>
			<Helmet>
				<title>Verifique seu e-mail - Legispedia</title>
				<meta name="description" content="Uma ferramenta em constante construção, criada para expandir a capacidade dos usuários em lidar com a complexidade do sistema jurídico brasileiro." />
			</Helmet>
		      <Container fixed>
		        <Grid container>
		          <Grid item sm={12} md={12}>
		            <img src={logo} alt="Legispedia" className="login-logo"/>

		            <div className="login-box">
		            	<h1 className="celular-title">Verifique seu e-mail</h1>
				        <Grid container>
				          <Grid item sm={12} md={8}>
				            	<p className="text color-bright-gray text-default">Para prosseguir você deve confirmar o seu e-mail clicando no link da mensagem que encaminhamos para você.</p>
				          </Grid>
				        </Grid>
	
		            	<Link to="/reenviar-confirmacao" className="text-small link-green celular-after">Enviar mensagem novamente</Link>
		            </div>	
		          </Grid>
		        </Grid>
		      </Container>
            </div>
        )
    }
}
