import React, { Component } from 'react'
import { CellMeasurer, CellMeasurerCache, AutoSizer, List } from 'react-virtualized';

type MyProps = { updateCurrentIndex:((index?: any) => void), getCurrentIndex:(() => number), activeOnly: boolean, legislacao: any[], searchResults: any[], searchText: string, searchResultIndex: number};
type MyState = { };


export default class LegislacaoTextView extends Component<MyProps, MyState> {
    constructor(props: MyProps) {
        super(props);
        this.state = {};
        this.handleSearchResult = this.handleSearchResult.bind(this);
    }

    startIndex = -1;
    stopIndex = -1;
    
    /**
     * this.scrollToIndex: é utilizado para realizar a navegação na lista. Ele irá se orientar prioritariamente para a busca. 
     * Todavia, se não houver busca ativa, ou seja, um this.props.searchResultIndex > -1, então irá utlizar o this.props.getCurrentIndex();
     */
    scrollToIndex ?= -1;

    cache = new CellMeasurerCache({
        defaultHeight: 50,
        fixedWidth: true
    });

    // Controla o tamanho dos cards quando a janela é redimensionada
    resizeTimeout: any;
    componentDidMount() {
        window.addEventListener("resize", this.resizeTimeoutHandler);
    }
    componentWillUnmount() {
        // Sempre que o componente for desmontado eu tenho que atualizar o índice atual no componente pai.
        // Assim, se houver resultado de pesquisa ativo eu o capturo, caso contrário eu pego o startIndex da Lista
        const currentIndex = (this.props.searchResultIndex > -1)? this.props.searchResultIndex:this.startIndex;
        this.props.updateCurrentIndex(currentIndex);

        window.removeEventListener("resize", this.resizeTimeoutHandler);
    }

    UNSAFE_componentWillMount(){
        //Aqui eu verifico se existe um indice de resultado ativo antes do primeiro <render>, assim eu o capturo. Caso contrário, eu pego o  <getCurrentIndex>
        this.scrollToIndex = (this.props.searchResultIndex > -1)? this.props.searchResultIndex:this.props.getCurrentIndex();
    }

    UNSAFE_componentWillUpdate(nextProps: any, nextState: any) {
        this.resizeVirtualWindow();

        if(this.props.searchResultIndex > -1){
            // Se houver um resultado ativo será direcionado para result index
            this.scrollToIndex = nextProps.searchResultIndex;
        }else if (this.startIndex !== -1){
            // Se a lista já foi inicializada, ou seja, o estado incial diferente de  -1
            // Assim eu seto o valor de <scrollToIndex> para undefined, assim a lista não sofrerá alteração na posição, mantendo-se na posição atual.
            this.scrollToIndex = undefined;            
        }else{
            // Se não existir um <resultado ativo> ou a lista <está em seu estado inicial, ou seja, monted>: captura o currente index. 
            // Pois pode ocorrer que logo depois do mounted o componente sofra atualização 
            this.scrollToIndex = this.props.getCurrentIndex();          
        }
    }

    resizeTimeoutHandler() {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(this.resizeVirtualWindow, 200);
    }
    resizeVirtualWindow() {
        this.cache.clearAll();
    }

    handleSearchResult(key: number, defaultText: string) {
        const parts = defaultText.split(new RegExp(`(${this.props.searchText})`, 'gi'));
        return (parts.map((part: any, i: number) => (this.props.searchText !== '' && part.toLowerCase() === this.props.searchText.toLowerCase()) ? <b key={`${key}-${i}`} className={'highlight-search'}>{part}</b> : part));
    }
    
    renderParagraph(index: any, isScrolling: any, key: any, parent: any, style: any) {
        const item = this.props.legislacao[index];
        return (
            <CellMeasurer cache={this.cache}
                          columnIndex={0}
                          key={key}
                          parent={parent}
                          rowIndex={index} >
                {({ measure, registerChild }) => (
                    <div key={key} style={style} onLoad={measure} ref={item.id} className={"nivel-" + item.tipoId + " " + (!item.substituido ? "active" : "expired")}>
                        <p><span className="titulo">{this.handleSearchResult(item.id, item.nome)}</span> {this.handleSearchResult(item.id, item.texto)}</p>
                    </div>
                )}
            </CellMeasurer>
        );
    }

    render() {

        /**
         * _scrollToAlignment: irá definir o layout de posicionamento do scrollToIndex do <List />. A busca utilizou o padrão, que é 'auto'. 
         * Mas como eu oriento a sincronização das listas fora da busca pelo startIndex (Primeiro índice visivel) do método onRowsRendered do <List />, se não houver busca irei utilizar o layout 'start'. 
         * Pois sempre que o componente se desmontar eu atualizo o listCurrentIndex do componente Pai pelo atual startIndex do <List /> do comonponente filho. 
         * Isso acontece no componentWillUnmount().
         * 
         */
        const _scrollToAlignment = (this.props.searchResultIndex > -1)? 'auto': 'start';
        
        return (
            <div className="card-view card-container text-view">
                <AutoSizer>
                    {({ height, width }) => (
                        <List rowCount={this.props.legislacao.length}
                                height={height}
                                deferredMeasurementCache={this.cache}
                                rowHeight={this.cache.rowHeight}
                                rowRenderer={({ index, isScrolling, key, parent, style }) => this.renderParagraph(index, isScrolling, key, parent, style)}
                                width={width}
                                scrollToAlignment={_scrollToAlignment}
                                scrollToIndex={this.scrollToIndex}
                                onRowsRendered={(params) => {
                                    this.startIndex = params.startIndex;
                                    this.stopIndex = params.stopIndex;
                                  }}
                                />
                    )}
                </AutoSizer>
            </div>
        )
    }
}
