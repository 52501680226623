import React, { Component } from "react";
import HeaderLegislacao from "./header-legislacao";
import FooterLegislacao from "./footer-legislacao";
import { Grid, CircularProgress } from "@material-ui/core";
import "./legislacao.scss";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { tabSelectedTypes } from "../../constants";
import LegislacaoCardView from "./legislacao-card-view";
import LegislacaoTextView from "./legislacao-text-view";
import LegislacaoSummaryView from "./legislacao-summary-view";
import ComentariosLegislacao from "./comentarios-legislacao";
import API from "../../API";
import * as IconFeather from 'react-feather';
import copy from 'copy-to-clipboard';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { environment } from '../../config/environment';
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "store/reducers";
import { Helmet } from "react-helmet";

const mapState = (state: AppState) => ({
  isLogged: state.isLogged,
  profile: state.profile
})
const mapDispatch = { }
const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
type RouterProps = RouteComponentProps<{id?: string; item?: string, comentarioId?: string }>
type MyProps = PropsFromRedux & WithSnackbarProps & RouterProps;
type MyState = { leiId?: number,
                 tabSelected: tabSelectedTypes,
                 prevTabSelected: tabSelectedTypes,
                 itemSelectedId?: number,
                 itemSelected: any,
                 activeOnly: boolean,
                 root: any, list: any, 
                 breadcrumb: string, 
                 comentarios: any[], 
                 legislacaoList: any, 
                 commentOptions: boolean,
                 commentFilterAcreditados: boolean,
                 commentOrderDate: boolean,
                 commentOrderLike: boolean,
                 loadingComments: boolean,
                 searchResults: any,
                 searchText: string,
                 searchActive: boolean,
                 ultimasBuscas: any[],
                 searchResultIndex: number
                 listCurrentIndex: number
                 query: any,
                };

class LegislacaoPage extends Component<MyProps, MyState> {
  separadorBreadCrumb = ' | ';
  
  constructor(props: Readonly<MyProps>) {
    super(props);
    this.state = {leiId: undefined,
                  tabSelected: 'default',
                  prevTabSelected: 'default',
                  itemSelectedId: undefined,
                  itemSelected: undefined,
                  activeOnly: false,
                  root: undefined,
                  list: undefined,
                  breadcrumb: '',
                  comentarios: [],
                  legislacaoList: undefined,
                  commentOptions: false,
                  commentFilterAcreditados: false,
                  commentOrderDate: true,
                  commentOrderLike: false,
                  loadingComments: false,
                  searchResults: [],
                  searchText: '',
                  searchActive: false,
                  ultimasBuscas: [],
                  searchResultIndex: -1,
                  listCurrentIndex: -1,
                  query: ''
                };
    this.handleCardClick = this.handleCardClick.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleActiveOnly = this.handleActiveOnly.bind(this);
    this.handleNewComment = this.handleNewComment.bind(this);
    this.handleReplyComment = this.handleReplyComment.bind(this);
    this.handleCommentOptions = this.handleCommentOptions.bind(this);
    this.handleDeleteComment = this.handleDeleteComment.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.defaultCommentFilterOrderSelection = this.defaultCommentFilterOrderSelection.bind(this);
    this.handleFilterComments = this.handleFilterComments.bind(this);
    this.handleOrderComments = this.handleOrderComments.bind(this);
    this.loadComments = this.loadComments.bind(this);
    this.handleSearchResult = this.handleSearchResult.bind(this);
    this.handleMovimentaBusca = this.handleMovimentaBusca.bind(this);
    this.findSearchIndex = this.findSearchIndex.bind(this);
    this.filterLegislacaoVigente = this.filterLegislacaoVigente.bind(this);
    this.updateCurrentIndex = this.updateCurrentIndex.bind(this);
    this.getCurrentIndex = this.getCurrentIndex.bind(this);
    this.findIndexById = this.findIndexById.bind(this);
  }

  async componentDidMount() {
    this.loadData();
    document.addEventListener('mousedown', this.handleClickOutside);
    document.body.classList.add("overflow-hidden");
  }

  componentDidUpdate (prevProps: any) {
    if (prevProps.location.key !== this.props.location.key) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.body.classList.remove("overflow-hidden");
  }

  defaultCommentFilterOrderSelection() {
    return (!this.state.commentFilterAcreditados && this.state.commentOrderDate && !this.state.commentOrderLike);
  }

  handleClickOutside(event: any) {
    const wrapperRef: Element = this.refs['menu-comment-opcoes'] as Element;
    const toggleButtonRef: Element = this.refs['toggle-menu-comment-opcoes'] as Element;
    if (this.state.commentOptions && wrapperRef && toggleButtonRef && !toggleButtonRef.contains(event.target) && !wrapperRef.contains(event.target)) {
      this.setState({commentOptions: false});
    }
  }

  async handleFilterComments() {
    let comentarios = [];
    if(this.state.itemSelectedId)
      comentarios = await this.loadComments(this.state.itemSelectedId, !this.state.commentFilterAcreditados, (this.state.commentOrderDate ? 'date' : 'likes'));
    this.setState({commentOptions: false, comentarios: comentarios, commentFilterAcreditados: !this.state.commentFilterAcreditados});
  }

  async handleOrderComments(option: string) {
    let orderDate = this.state.commentOrderDate;
    let orderLike = this.state.commentOrderLike;
    if(option === 'date')
    {
      orderDate = !orderDate;
      if(orderLike) 
        orderLike = !orderLike;
    }
    if(option === 'like')
    {
      orderLike = !orderLike;
      if(this.state.commentOrderDate)
        orderDate = !orderDate;
    }
    if(!orderDate && !orderLike)
      orderDate = true;

    let comentarios = [];
    if(this.state.itemSelectedId)
      comentarios = await this.loadComments(this.state.itemSelectedId, this.state.commentFilterAcreditados, (orderDate ? 'date' : 'likes'));
    this.setState({commentOptions: false, comentarios: comentarios, commentOrderDate: orderDate, commentOrderLike: orderLike});
  }

  async loadData() {
    if (this.props.match) {
      let leiId = this.props.match.params.id;

      try
      {
        if (leiId) {
          let request = await API.get('/legislacao/tree/' + leiId);

          let itemId = (this.props.match.params.item)?this.props.match.params.item:'';
          let currentIndex = this.findIndexById(parseInt(itemId), request.data.list);
          
          this.setState({leiId: parseInt(leiId), root: request.data.root, list: request.data.list, listCurrentIndex: currentIndex });
 
          if (itemId) {
            this.handleCardClick(parseInt(itemId));
          }
        } else {
          this.props.history.push('/legislacao-lista');
        }
      }
      catch(e)
      {
        if(e.response) {
          if(e.response.status === 404) {
            this.props.history.push('/404');
          } else {
            e.response.data.errors.forEach((element: any) => {
                this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
            });
          }
        }
        else {
          this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
        }
      }
    }
  }

  async handleCardClick(id?: number) {
    let breadcrumb = '';
    let comentarios = [];
    let itemSelected = undefined;
    if(id !== undefined)
    {
      this.setState({loadingComments: true});
      itemSelected = this.state.list.filter((item :any) => {return item.id === id})[0];
      breadcrumb = this.recursiveBreadCrumb(id, "");
      breadcrumb = breadcrumb.substring(0, breadcrumb.lastIndexOf(this.separadorBreadCrumb));
      comentarios = await this.loadComments(id, this.state.commentFilterAcreditados, (this.state.commentOrderDate ? 'date' : 'likes'));
    }

    this.setState({itemSelectedId: id, breadcrumb: breadcrumb, comentarios: comentarios, itemSelected: itemSelected});
  }

  async loadComments(id: number, filter: boolean, order: string) {
    try
    {
      let request = await API.get('/comentario/legislacao/' + id + '?filtrarAcreditados=' + filter + '&order=' + order); 
      this.setState({loadingComments: false});      
      return request.data.result;
    }
    catch(e)
    {
      this.setState({loadingComments: false});  
      if(e.response) {
        e.response.data.errors.forEach((element: any) => {
            this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
        });
      }
      else {
        this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
      }
    }
  }

  handleTabChange(tab: tabSelectedTypes){
    if(this.state.tabSelected === tab) {
      const next = this.state.prevTabSelected === 'summary' && this.state.tabSelected === 'text-view' ? 'default' : this.state.prevTabSelected;
      this.setState({prevTabSelected: tab, tabSelected: next});
    } else {
      this.setState({prevTabSelected: this.state.tabSelected, tabSelected: tab});
    }
  }

  handleNewComment(comentario: any) {
    if(this.state.comentarios && this.state.comentarios.length > 0) {
      this.setState({comentarios: [comentario, ...this.state.comentarios]});
    } else {
      this.setState({comentarios: [comentario]});
    }
  }
  
  handleDeleteComment(comentarioId: number) {
    var array = [...this.state.comentarios];
    var index = array.findIndex((item: any) => item.id === comentarioId)
    if (index !== -1) {
      array.splice(index, 1);
    } else {
      for (let i = 0; i < array.length; i++) {
        const indexResposta = array[i].respostas.findIndex((item: any) => item.id === comentarioId);
        if (indexResposta !== -1)
        {
          array[i].respostas.splice(indexResposta, 1);
        }
      }
    }
    this.setState({comentarios: array});
  }

  handleReplyComment(comentarioId: number, resposta: any) {
    const commentIndex = this.state.comentarios.findIndex(item => item.id === comentarioId)
    this.setState({
      comentarios: [
        ...this.state.comentarios.slice(0, commentIndex),
        {
          ...this.state.comentarios[commentIndex],
          respostas: [resposta, ...this.state.comentarios[commentIndex].respostas]
        },
        ...this.state.comentarios.slice(commentIndex + 1),
      ]
    });
    this.forceUpdate();
  }

  handleSearchResult(results: any, text: string) {
    this.setState({searchResults: results, searchText: text, searchResultIndex: 0 });
    this.handleMovimentaBusca(0);
  }

  handleMovimentaBusca(index: number) {
    if (index < 0) {
      index = this.state.searchResults.length - 1;
    }
    else if (index >= this.state.searchResults.length) {
      index = 0;
    }
    this.setState({ searchResultIndex: index });
  }

  findSearchIndex() {
    if (this.state.searchResultIndex === undefined) {
      return undefined;
    }
    else {
      return this.state.list.findIndex((item: any) => item.id === this.state.searchResults[this.state.searchResultIndex as number])
    }
  }

  findIndexById(id: number, list?: any) {
    let _list = []
    
    if(list){
      _list = list;
    }else{
      _list = this.state.list;
    }

    return _list.findIndex((item: any) => { return item.id === id });
  }

  handleCommentOptions(event: any) {
    event.preventDefault();
    this.setState({commentOptions: !this.state.commentOptions});
  }

  handleActiveOnly(active: boolean) {
    this.setState({activeOnly: active});
  }

  recursiveBreadCrumb(id: number, result: string): string {
    let item = this.state.list.filter((element :any) => { return element.id === id});
    if (item.length > 0)
    {
      result = item[0].nome + this.separadorBreadCrumb + result;
      let pai = this.state.list.filter((element :any) => { return element.id === item[0].paiId});
      if(pai.length > 0)
      {
        result = this.recursiveBreadCrumb(pai[0].id, result);
      }
      return result;
    }
    else
    {
      return result;
    }
  }

  handleCompartilhar() {
		copy(window.location.origin + '/legislacao' + (this.state.leiId ? ('/' + this.state.leiId) : '') + (this.state.itemSelectedId ? ('/' + this.state.itemSelectedId) : ''));
		this.props.enqueueSnackbar('Link copiado!', { variant: 'success' });
  }
  
  filterLegislacaoVigente() {
    if (!this.state.activeOnly) {
      return this.state.list;
    } else {
      return this.state.list.filter((item: any) => !item.substituido);
    }
  }
  
  /**
   * 
   * @param index 
   * 
   * Atualiza o Índece do compoenente pai this.state.listCurrentIndex, que é compartilahado entre os dois componentes filhos <LegislacaoTextView /> <LegislacaoCardView />.
   * Esta função é chamada sempre que o componente filho é desmontado, utilizando o componentWillUnmount() do próprio componente filho.
   * Este método é passado para os componentes filhos por meio de um props com bind.
   * <index>: recebe o valor do startIndex do método onRowsRendered() no componente <List /> dos compoenentes filhos
   */
  updateCurrentIndex(index:any) {
    this.setState({listCurrentIndex: index})

  }
  /**
   * Captura qual o Índece corrente do compoenenete pai, que é atualizado pelo método: updateCurrentIndex
   * É utilizado para atualizar o estado dos compoenentes filhos sempre que eles forem montados, utilizando o método componentDidMount() dos componentes filhos <LegislacaoTextView /> <LegislacaoCardView /> 
   * Este método é passado para os componentes filhos por meio de um props com bind.
   */
  getCurrentIndex() {
    return this.state.listCurrentIndex;
  }

  render() {

    if(this.state.root === undefined)
      return <div className="legislacao-loading"><CircularProgress /></div>;
    return (
      <div>
        <Helmet>
          <title>{this.state.root.nome} - Legislação - Legispedia</title>
          <meta name="description" content={this.state.root.texto} />
        </Helmet>

        <Grid
          container
          direction="row"
          alignItems="stretch"
          className={"container-legislacao " + (this.state.itemSelectedId ? "item-selected comentario-expandido" : "")}
        >
          <Grid className={"left-panel" + (this.state.itemSelectedId ? " comentario-expandido": "")} item sm={12} md={4} container direction="column" alignItems="stretch">
              <HeaderLegislacao lei={this.state.root} onSearchResults={this.handleSearchResult} onActiveChange={(active: boolean) => { this.setState({searchActive: active}); }} onMovimentaBusca={this.handleMovimentaBusca} resultLength={this.state.searchResults.length} resultIndex={this.state.searchResultIndex} />
              <Grid className="left-panel-content" container direction="column" alignItems="stretch">
                { this.state.tabSelected === "text-view" ? 
                  <LegislacaoTextView getCurrentIndex={this.getCurrentIndex} updateCurrentIndex={this.updateCurrentIndex} legislacao={this.filterLegislacaoVigente()} searchResults={this.state.searchResults} searchResultIndex={this.findSearchIndex()} searchText={this.state.searchText} activeOnly={this.state.activeOnly} ></LegislacaoTextView> 
                : (this.state.tabSelected === "summary" ? 
                  <LegislacaoSummaryView legislacao={this.state.list} activeOnly={this.state.activeOnly} onSummarySelect={(id: number) => { this.handleCardClick(id); this.setState({tabSelected: 'default', listCurrentIndex: this.findIndexById(id) }) }} ></LegislacaoSummaryView> 
                : <LegislacaoCardView getCurrentIndex={this.getCurrentIndex} updateCurrentIndex={this.updateCurrentIndex} searchResults={this.state.searchResults} searchResultIndex={this.findSearchIndex()} searchText={this.state.searchText} itemSelectedId={this.state.itemSelectedId} legislacao={this.filterLegislacaoVigente()} resultIndex={this.state.searchResultIndex} activeOnly={this.state.activeOnly} onChangeSelected={this.handleCardClick}></LegislacaoCardView>)}  
                  { this.props.isLogged && this.state.searchActive && this.state.ultimasBuscas.length > 0 ? (
                    <div className="pesquisas-recentes">
                      <h3 className="title-normal">PESQUISAS RECENTES <span className="float-right color-weak-green"><IconFeather.Trash/></span></h3>
                      
                      <p className="item-pesquisa text-default"><IconFeather.Clock /> placeholder</p>
                      <p className="item-pesquisa text-default"><IconFeather.Clock /> placeholder</p>
                    </div>
                  ) : '' }
              </Grid>
              <FooterLegislacao activeOnly={this.state.activeOnly} activeOnlyChanged={this.handleActiveOnly} tab={this.state.tabSelected} tabChanged={this.handleTabChange}></FooterLegislacao>
          </Grid>
          <Grid className={"right-panel"} item sm={12} md={8} container direction="column" alignItems="stretch">
            { this.state.loadingComments ? <div className="legislacao-loading"><CircularProgress /></div> : (
              this.state.itemSelectedId ? (
                <ComentariosLegislacao lei={this.state.root} comentarios={this.state.comentarios} leiId={this.state.leiId} legislacao={this.state.itemSelected} breadCrumb={this.state.breadcrumb} onNewComment={this.handleNewComment} onReplyComment={this.handleReplyComment} onDeleteComment={this.handleDeleteComment} itemSelectedId={this.props.match.params.comentarioId} />
              ) : ''
            )}
            <div className="footer-legislacao">
              <button className={"btn-legislacao p-relative " + (!this.defaultCommentFilterOrderSelection() ? 'active' : '')} onClick={this.handleCommentOptions} ref={'toggle-menu-comment-opcoes'} title="Filtrar Comentário"><IconFeather.Sliders />
                { this.state.commentOptions ? <div className="menu-comment-opcoes" ref={'menu-comment-opcoes'}>
                    <h4 className="title-normal">Filtrar Comentários</h4>
                    <button className="color-bright-gray text-default mb-10" onClick={this.handleFilterComments}>Somente Acreditados { this.state.commentFilterAcreditados ? <IconFeather.Check /> : ''}</button>

                    <h4 className="title-normal">Ordenar Comentários</h4>
                    <button className="color-bright-gray text-default" onClick={() => this.handleOrderComments('date') }>Mais Recentes { this.state.commentOrderDate ? <IconFeather.Check /> : ''}</button>
                    <button className="color-bright-gray text-default" onClick={() => this.handleOrderComments('like') }>Mais Votados  { this.state.commentOrderLike ? <IconFeather.Check /> : ''}</button>
                  </div> : '' }
              </button>
              <button className="btn-legislacao" onClick={this.handleCompartilhar.bind(this)} title="Copiar Link"><IconFeather.Upload /></button>

            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withSnackbar(withRouter(connector(LegislacaoPage)));