import React, { Component } from 'react';
import { CircularProgress } from "@material-ui/core";
import * as Icon from 'react-feather';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import API from 'API';
import { AppState } from 'store/reducers';
import { connect, ConnectedProps } from 'react-redux';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { environment } from 'config/environment';

const mapState = (state: AppState) => ({
	isLogged: state.isLogged,
	profile: state.profile
})

const mapDispatch = {}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
type RouterProps = RouteComponentProps<{}>

type MyProps = RouterProps & PropsFromRedux & WithSnackbarProps;
type MyState = { query: string, ultimasBuscas: any, loading: boolean, buscasRecentesOpen: boolean };

class BuscaLegislacao extends Component<MyProps, MyState> {

	constructor(props: MyProps) {
		super(props);
		this.state = { query: "", ultimasBuscas: undefined, loading: true, buscasRecentesOpen: false};
		this.fillBuscasRecentes = this.fillBuscasRecentes.bind(this);
		this.handleBusca = this.handleBusca.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside(event: any) {
		const wrapperRef: Element = this.refs['buscas-recentes-panel'] as Element;
		if (this.state.buscasRecentesOpen && wrapperRef && !wrapperRef.contains(event.target)) {
			this.setState({buscasRecentesOpen: false});
		}
	}

	handleBusca() {
		if(this.state.query !== ''){
			if(this.props.isLogged){
				try
				{
					API.post('/busca', {
							texto: this.state.query
						},
						{
						headers: {
							'Authorization': `Bearer ${this.props.profile.token}`
						}
					});
				}
				catch(e)
				{
					if(e.response) {
						e.response.data.errors.forEach((element: any) => {
							this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
						});
					} else {
						this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
					}
				}
			}
			this.props.history.push('/busca-legislacao?q=' + this.state.query);
		}
	}

	async fillBuscasRecentes() {
		if(this.props.isLogged && this.state.ultimasBuscas === undefined)
		{
			try
			{
				const request = await API.get('/busca', {
				headers: {
					'Authorization': `Bearer ${this.props.profile.token}`
				}
				});
				if(request.data.buscas)
				{
					this.setState({ultimasBuscas: request.data.buscas});
				}
			}
			catch(e)
			{
				if(e.response) {
					e.response.data.errors.forEach((element: any) => {
						this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
					});
				} else {
					this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
				}
			}
		}
	}

    render() {
        return (
            <div className={"input-search " + (this.state.buscasRecentesOpen ? 'open' : '')} ref={'buscas-recentes-panel'}>
            	<i className="icon-left"><Icon.Search /></i>
				<input type="text" placeholder="Buscar por leis ou enunciados normativos" className="has-icon-left has-icon-right" onChange={(e) => this.setState({ query: e.target.value })} onKeyDown={(e) => {if (e.key === 'Enter') {this.handleBusca()}}} onFocus={() => {this.fillBuscasRecentes(); this.setState({buscasRecentesOpen: true});}}/>
                <i className="icon-right cursor-pointer" onClick={() => this.handleBusca()}><Icon.ArrowRight /></i>
            	
            	<div className="recent-search bg-light-gray">
            		{this.state.ultimasBuscas !== undefined && this.state.ultimasBuscas.length > 0 ? (
						this.state.ultimasBuscas.map((item: any, index: number) => (
	            			<input key={item.id} readOnly onClick={(event: any) => this.props.history.push('/busca-legislacao?q=' + event.target.value)} value={item.texto}/>
	            		))
            		): <div className="legislacao-loading"><CircularProgress /></div>}
            		
            	</div>
            </div>
        )
    }
}

export default withSnackbar(withRouter(connector(BuscaLegislacao)))
