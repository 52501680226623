import React, { Component } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import logo from "../../assets/icons/logo-big.svg";
import API from "../../API";
import { ConnectedProps, connect } from "react-redux";
import { AppState } from "store/reducers";
import { UserProfile, REFRESH_PROFILE, SET_TOKEN, SET_ROLES } from "store/types/userProfile";
import { withSnackbar, WithSnackbarProps } from 'notistack';
import FacebookLogin from 'react-facebook-login';
import { environment } from 'config/environment';
import { GoogleLogin } from 'react-google-login';
import * as queryString from 'query-string';
import { Helmet } from "react-helmet";

  const mapState = (state: AppState) => ({
    isLogged: state.isLogged
  })
  
  const mapDispatch = {
    signIn: () => ({ type: 'SIGN_IN' }),
    fetchLikes: (array: number[]) => ({type: 'FETCH_LIKES', payload: array}),
    refreshProfile: (profile: UserProfile) => ({ type: REFRESH_PROFILE, payload: profile }),
    setToken: (token: string) => ({type: SET_TOKEN, payload: token}),
    setRoles: (roles: string[]) => ({type: SET_ROLES, payload: roles}),
  }
  
  const connector = connect(mapState, mapDispatch)
  type PropsFromRedux = ConnectedProps<typeof connector>
  
  type RouterProps = RouteComponentProps<{}>
  
  type Props = PropsFromRedux & WithSnackbarProps & RouterProps & {
    hover?: boolean;
  }
  
  type MyState = {
    username: string;
    password: string;
    loading: boolean;
    isHovering: boolean;
  };
  
  class LoginPage extends Component<Props, MyState> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = { username: "", password: "", loading: false, isHovering: this.props.hover ? this.props.hover : false};
        if(this.props.isLogged)
            this.props.history.push("/");

        this.handleSocialLogin = this.handleSocialLogin.bind(this)
    }

    componentDidMount() {
        document.body.classList.add("bg-light-gray");
    }

    async handleSubmit(e: any) {
        e.preventDefault();
        this.setState({ loading: true });
        const { username, password } = this.state;

        try {
            let userData = await API.post('/login', {
                email: username,
                senha: password
            });
            this.loginSuccess(userData.data.token);
		} catch (e) {
            if(e.response.status === 403) { // e-mail não confirmado
                this.props.history.push('/aguardando-confirmacao');
            } else {
                if(e.response) {
                    e.response.data.errors.forEach((element: any) => {
                        this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
                    });
                }
                else {
                    this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
                }
            }
            this.setState({loading: false});
		}
    }

    async handleSocialLogin(data: any, provider: string) {
        if(data && data.accessToken)
        {
            try
            {
                var result = await API.post(`/login/${provider}`, { token: data.accessToken })
                if(result && result.data.token)
                {
                    this.loginSuccess(result.data.token);
                }
            }
            catch(e)
            {
                if(e.response) {
                  e.response.data.errors.forEach((element: any) => {
                      this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
                  });
                }
                else {
                  this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
                }
            }
        }
    }

    async loginSuccess(token: string) {
        try
        {
            let likes = await API.get('/comentario/liked', {
                headers: {
                  'Authorization': `Bearer ${token}`
                }});
            
            let request = await API.get('/profile', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }});
                
            localStorage.setItem("userToken", token);
            this.props.setToken(token);
            this.props.setRoles(request.data.roles);
            this.props.refreshProfile(request.data.user);
            this.props.signIn();
            this.props.fetchLikes(likes.data.comentarios);
            if (!this.props.hover)
            {
                const qs = queryString.parse(this.props.history.location.search);
                if (qs.returnUrl) {
                    this.props.history.push(qs.returnUrl as string);
                } else {
                    this.props.history.push("/");
                }
            }else{
                this.props.enqueueSnackbar('Login efetuado com sucesso', { variant: 'success' }) 
            }
        }
        catch(e)
        {
            if(e.response) {
              e.response.data.errors.forEach((element: any) => {
                  this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
              });
            }
            else {
              this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
            }
        }
    }

    render() {
        return (
            <div>
                <Helmet>
                <title>Login - Legispedia</title>
                <meta name="description" content="A Legispedia é uma ferramenta em constante construção, criada para expandir a capacidade dos usuários em lidar com a complexidade do sistema jurídico brasileiro." />
                </Helmet>
                <Container fixed>
                    <Grid container>
                        <Grid item xs={12}>
                            {!this.state.isHovering && (<img src={logo} alt="Legispedia" className="login-logo" />)}
                            <div className="login-box input-margin">
                                <h1 className="login-title">Faça Login</h1>

                                {!this.state.isHovering ? (
                                    <div>
                                        <GoogleLogin 
                                            clientId={environment.google.appId}
                                            buttonText="Continuar com Google"
                                            render={renderProps => 
                                                <button onClick={renderProps.onClick} className="input login-with google" disabled={renderProps.disabled}>
                                                    <i className="icon icon-google"></i>
                                                    Continuar com Google
                                                </button>
                                            }
                                            onSuccess={(data: any) => this.handleSocialLogin(data, 'google')}
                                            onFailure={(data: any) => this.handleSocialLogin(data, 'google')}
                                            cookiePolicy={'single_host_origin'}
                                        />
                                        <div className="login-with facebook">
                                            <FacebookLogin
                                                appId={environment.facebook.appId}
                                                autoLoad={false}
                                                fields="name,email"
                                                scope="public_profile,email"
                                                callback={(data: any) => this.handleSocialLogin(data, 'facebook')} 
                                                cssClass="input login-with facebook">
                                            </FacebookLogin>
                                            <span>
                                                <i className="icon icon-facebook"></i>
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <Grid container>
                                        <Grid item sm={6} md={6}>
                                            <GoogleLogin 
                                                clientId={environment.google.appId}
                                                buttonText="Continuar com Google"
                                                render={renderProps => 
                                                   <button onClick={renderProps.onClick} className="login-with google" disabled={renderProps.disabled}>
                                                        <i className="icon icon-google"></i>
                                                    </button>
                                                }
                                                onSuccess={(data: any) => this.handleSocialLogin(data, 'google')}
                                                onFailure={(data: any) => this.handleSocialLogin(data, 'google')}
                                                cookiePolicy={'single_host_origin'}
                                            />
                                        </Grid>
                                        <Grid item sm={6} md={6}>
                                            <div className="login-with facebook">
                                                <FacebookLogin
                                                    appId={environment.facebook.appId}
                                                    autoLoad={false}                                        
                                                    fields="name,email"
                                                    scope="public_profile,email"
                                                    callback={(data: any) => this.handleSocialLogin(data, 'facebook')} 
                                                    cssClass="input login-with facebook">
                                                </FacebookLogin>
                                                <span>
                                                    <i className="icon icon-facebook"></i>
                                                </span>
                                            </div>
                                        </Grid>
                                    </Grid>
                                )}

                                {!this.state.isHovering ? (
                                    <span className="login-separator">Ou</span>
                                ) : (
                                    <span className="login-separator"></span>
                                )}

                                <form name="form" onSubmit={(e) => this.handleSubmit(e)}>
                                    <input type="email" placeholder="Email" className="input" value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} />
                                    <input type="password" placeholder="Senha" className="input" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                                    
                                    <input type="submit" className="input btn-submit bg-weak-green borderless color-white" value="ENTRAR" disabled={this.state.loading}/>
                                </form>
                                <Link to="/recuperar-senha" className="text-small link-green link-after">Esqueci minha senha</Link>
                                <Link to="/criar-conta" className="text-small color-bright-gray text-default">Ainda não tem conta? <strong className="color-weak-green">Crie sua conta</strong> grátis!</Link>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    }
  }

  export default withSnackbar(withRouter(connector(LoginPage)))