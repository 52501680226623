import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore } from 'redux';
import { rootReducers }  from './store';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__();

const store = createStore(
    rootReducers, 
    composeEnhancers);

const notistackRef = React.createRef<any>();
const onClickDismiss = (key: any) => () => { 
    notistackRef.current.closeSnackbar(key);
}

ReactDOM.render(
<Provider store={store}>
    <SnackbarProvider 
        ref={notistackRef}
        maxSnack={8}     
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        preventDuplicate
        action={(key) => (
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                className={'close'}
                onClick={onClickDismiss(key)}
              >
                <CloseIcon />
              </IconButton>
            </React.Fragment>
        )}
    >
        <App />    
    </SnackbarProvider>
</Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
