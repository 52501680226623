import React, { Component } from 'react'
import { fetchUtils, Admin, Resource } from 'react-admin';
// import simpleRestProvider from 'ra-data-simple-rest';
import customRestProvider from './data-provider';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import authProvider from './auth-provider';
import { createHashHistory } from 'history';
import createAdminStore from './admin-store';
import { Provider } from 'react-redux';
import { LegislacaoList, LegislacaoShow, LegislacaoCreate, LegislacaoEdit } from './legislacao';
import { UserList, UsersShow } from './users';
import { TermoList, TermoCreate, TermoEdit } from './termos';
import { PoliticasList, PoliticaCreate, PoliticaEdit } from './politicas';
import { DenunciasList } from './denuncias';
import { SolicitacaoList } from './solicitacoes';
import { ComentariosList } from './comentarios';

import UserIcon from '@material-ui/icons/Group';
import CommentIcon from '@material-ui/icons/Comment';
import InfoIcon from '@material-ui/icons/Info';
import LockIcon from '@material-ui/icons/Lock';
// import CommentIcon from '@material-ui/icons/Comment'
import WarningIcon from '@material-ui/icons/Warning'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import './admin.scss';
import { environment } from 'config/environment';
import Dashboard from './dashboard';


interface MyProps extends RouteComponentProps<{}> {}  
type MyState = { };

class AdminPage extends Component<MyProps, MyState> {
    componentWillMount() {
        document.body.classList.add('admin');
    }
    componentWillUnmount() {
        document.body.classList.remove('admin');
    }

    httpClient = (url: string, options: any = {}) => {
        if (!options.headers) {
            options.headers = new Headers({ Accept: 'application/json' });
        }
        const token = localStorage.getItem('token');
        options.headers.set('Authorization', `Bearer ${token}`);
        return fetchUtils.fetchJson(url, options);
    };
    dataProvider = customRestProvider(environment.serverUrl, this.httpClient);
    historyProvider = createHashHistory();

	constructor(props: Readonly<MyProps>) {
        super(props);
        this.state = { };
	}

    render() {
        return (
            <Provider
                store={createAdminStore({
                    authProvider: authProvider,
                    dataProvider: this.dataProvider,
                    history: this.historyProvider
                })}
            >
                <Admin dashboard={Dashboard} dataProvider={this.dataProvider} authProvider={authProvider} history={this.historyProvider}>
                    <Resource name="users" options={{ label: 'Usuários' }} list={UserList} show={UsersShow} icon={UserIcon} />
                    <Resource name="legislacao" options={{ label: 'Legislações' }} edit={LegislacaoEdit} list={LegislacaoList} show={LegislacaoShow} create={LegislacaoCreate} />
                    <Resource name="legislacoes-itens"  />
                    <Resource name="comentarios" options={{ label: 'Comentários' }} list={ComentariosList} icon={CommentIcon}/>
                    <Resource name="tipos" options={{ label: 'Tipos' }} />
                    <Resource name="assign" options={{ label: 'User Roles Assignment'}} />
                    <Resource name="legislacao-referencia" options={{ label: 'Legislações-Referencia' }} />
                    <Resource name="denuncia" options={{ label: 'Denúncias' }} list={ DenunciasList } icon={WarningIcon} />
                    <Resource name="solicitacao" options={{ label: 'Solicitações' }} list={ SolicitacaoList } icon={VerifiedUserIcon} />
                    <Resource name="termos" options={{ label: 'Termos de Uso' }} list={ TermoList } create={TermoCreate} edit={TermoEdit} icon={InfoIcon} />
                    <Resource name="politicas" options={{ label: 'Políticas de Privacidade' }} list={ PoliticasList } create={PoliticaCreate} edit={PoliticaEdit} icon={LockIcon} />
                </Admin>
            </Provider>
        )
    }
}

export default withRouter(AdminPage)
