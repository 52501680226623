import React, { Component } from 'react'
import { Box } from '@material-ui/core';

type MyProps = { activeOnly: boolean, legislacao: any[], onSummarySelect: (summaryId: number) => void };
type MyState = {  };


export default class LegislacaoSummaryView extends Component<MyProps, MyState> {
    handleFilter() {
        let result = this.props.legislacao;
        return result.filter(item => { return item.sumario});
    }
    
    render() {
        return (
            <div className="card-view card-summary card-container scroll overflow-y">
                <div className="card-scroll">
                    {this.handleFilter().map((item: any, index) => (
                        <Box key={item.id} className={'nivel-' + item.tipoId} onClick={() => this.props.onSummarySelect(item.id) }>
                            <h1 className="text-slab">{item.nome}</h1>
                            <h2 className="text-default color-bright-gray">{item.texto}</h2>
                        </Box>
                    ))}
                </div>
            </div>
        )
    }
}
