export const SIGN_IN = "SIGN_IN"
export const SIGN_OUT = "SIGN_OUT"

export interface SignInAction {
    type: typeof SIGN_IN;
}
export interface SignOutAction {
    type: typeof SIGN_OUT;
}

export type IsLoggedActionTypes = SignInAction | SignOutAction;