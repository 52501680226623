import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as Icon from 'react-feather';
import logo from '../assets/icons/logo-compact.svg';
import placeholder from "../assets/profile.png";
import './side-menu.scss';
import { LoginPage }  from "pages";
import { AppState } from "store/reducers";
import { connect, ConnectedProps } from "react-redux";

const mapState = (state: AppState) => ({
  isLogged: state.isLogged,
  profile: state.profile
})
const mapDispatch = {}
const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux

type MyState = { isHoveringLogin: boolean };

class SideMenu extends Component<Props, MyState> {
  constructor(props: Readonly<Props>) {
		super(props);
		this.state = { isHoveringLogin: false};
  }
  
  renderPerfilButton() {
    if(this.props.isLogged) {
      return <Link to="/perfil" title="Meu Perfil"><div className="wrap-img wrap-img-36" style ={ { backgroundImage: "url("+ (this.props.profile.foto ? this.props.profile.foto : placeholder) +")" } }></div></Link>;
    } else {
      return (
        <div onMouseOver={this.mouseOver} onMouseOut={this.mouseOut}>
          <Link to="/login" title="Fazer Login">
            <Icon.User width="30" height="30"/>
          </Link>
          <div className={"login-hover " + (!this.state.isHoveringLogin ? "hidden" : "show")}>
            <LoginPage hover={true}></LoginPage>
          </div> 
        </div>
      );
    }
  }
  mouseOver = () => this.setState({ isHoveringLogin: true })
  mouseOut = () => this.setState({ isHoveringLogin: false })

  render() {
    return (
        <div className="side-menu">
          <span className="side-logo"><Link to="/" title="Início"><img src={logo} alt="Legispedia" width="30" height="30"/></Link></span>
          <ul>
            <li>
              <Link to="/" title="Início"><Icon.Home width="30" height="30"/></Link>
            </li>
            <li>
              <Link to="/legislacao-lista" title="Legislações"><Icon.BookOpen width="30" height="30" /></Link>
            </li>
            <li className="item-bottom">
              <Link to="/termos" title="Termos de Uso e Política de Privacidade"><Icon.Info width="30" height="30" /></Link>
            </li>
            <li className="login-wrapper">
              { this.renderPerfilButton() }
            </li>
          </ul>
        </div>
    );
  }
}
export default connector(SideMenu)