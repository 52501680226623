import React, { lazy, Suspense } from 'react'
import { List, Datagrid, TextField, DateField, Create, Edit, SimpleForm, TextInput, required } from 'react-admin'

const LazyRichTextInput = lazy(() => import('ra-input-rich-text') );
const renderLoader = () => <p>&nbsp;</p>;

export const PoliticasList = props => (
    <List {...props} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="versao" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
            <DateField source="deletedAt" />
        </Datagrid>
    </List>
);

export const PoliticaCreate = (props) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <TextInput source="versao" validate={required()} />
            <Suspense fallback={renderLoader()}><LazyRichTextInput source="texto" validate={required()} /></Suspense>
        </SimpleForm>
    </Create>
);


export const PoliticaEdit = props => {
    return (
        <Edit undoable={false} {...props}>
            <SimpleForm>
                <TextInput source="versao" />
                <Suspense fallback={renderLoader()}><LazyRichTextInput source="texto" /></Suspense>
            </SimpleForm>
        </Edit>
    )
};