import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import API from "../../API";
import './home.scss';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { environment } from '../../config/environment';

type MyProps = WithSnackbarProps & {}; 
type MyState = { leis: any, loading: boolean };

class UltimasLeis extends Component<MyProps, MyState> {
  constructor(props: Readonly<MyProps>) {
    super(props);
    this.state = { leis: undefined, loading: true };
  }

  async componentDidMount() {
    try
    {
      let request = await API.get('/legislacao/ultimas/5');
      this.setState({leis: request.data.lesgislacoes, loading: false});
    }
    catch(e)
    {
      if(e.response) {
        e.response.data.errors.forEach((element: any) => {
            this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
        });
      }
      else {
        this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
      }
    }
  }

  render() {
    return (
      <div className="ultimas-leis">
        <div className="box-light-gray d-inline-block">
          <h3 className="title-small uppercase">Últimas leis adicionadas</h3>
          { this.state.loading ? <div className="legislacao-loading"><CircularProgress /></div> : (
            <ul style={{ listStyleType: "none", padding: 0 }}>
              { this.state.leis ? this.state.leis.map((lei: any, index: number) => (
                <li key={lei.id} className="color-bright-gray">
                  <Link to={"/legislacao/" + lei.id}>
                    {lei.nome} - <span>{lei.texto}</span>
                  </Link>
                </li>
              )) : ""}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

export default withSnackbar(UltimasLeis)