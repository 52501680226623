import React, { Component, lazy, Suspense } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Grid } from "@material-ui/core";
import { AppState } from '../../store/reducers';
import { withSnackbar, WithSnackbarProps } from 'notistack';

const LazyPasswordStrengthBar = lazy(() => import('react-password-strength-bar') );
const renderLoader = () => <p>&nbsp;</p>;

const mapState = (state: AppState) => ({ })
const mapDispatch = { }  
const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
  
type Props = PropsFromRedux & WithSnackbarProps & { model: any, title: string, maxlength?: number, field: string, onConfirmarEdicao: (model: any, field: string) => Promise<boolean> }
type MyState = { model: any, active: boolean, senhavalida: boolean, senhaInvalido: boolean, confirmarsenha: string, celular: string, loading: boolean };

class ConfiguracoesPerfilItem extends Component<Props, MyState> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            model: this.props.model,
            active: false,
            senhavalida: false,
            senhaInvalido: false,
            confirmarsenha: "",
            celular: "",
            loading: false
        };
        this.selectFieldType = this.selectFieldType.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSenhaInvalido = this.handleSenhaInvalido.bind(this);
    }
    
    selectFieldType() {
        switch (this.props.field) {
            case 'senha':
                return 'password'
            case 'email':
                return 'email'
            default: 
                return 'text'
        }
    }

    handleConfirmarSenha (e: any) {
      const senha = this.state.model;
      const confirmarsenha = this.state.confirmarsenha;

      if(confirmarsenha === senha){
          this.setState({senhavalida: false});
      }else{
          this.setState({senhavalida: true});
      }

    }

    handleTelefoneInvalido (e: any) {
      let telefone = e;

      let numbers =  telefone.match(/\d/g);
      let numberLength = 0;

      if (numbers) {
        numberLength = numbers.join("").length;
      }
      //console.log(numberLength);
      telefone = telefone.replace(/\D/g, '');
      telefone = telefone.replace(/(\d{1})(\d)/, '($1$2');
      telefone = telefone.replace(/(\d{2})(\d)/, '$1) $2');
     

      if (numberLength > 10) {
           telefone = telefone.replace(/(\d{5})(\d{1,2})/, '$1-$2');

      } else {
          telefone = telefone.replace(/(\d{4})(\d{1,2})/, '$1-$2');
      }
      return telefone;
    }

    handleSenhaInvalido (e: any) {
        if (this.props.field === 'senha') {
            const senha = this.state.model;
            const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
      
            if(re.test(senha)){
                this.setState({senhaInvalido: false});
            }else{
                this.setState({senhaInvalido: true});
            }
        }
      }

    async handleSave () {
        if(this.props.field === 'senha' && this.state.model !== this.state.confirmarsenha) {
            this.props.enqueueSnackbar('As senhas não conferem.', { variant: 'error' }) 
        } else {
            this.setState({loading: true})
            var result = await this.props.onConfirmarEdicao(this.state.model, this.props.field)
            if (result)
                this.setState({active: false})
    
            this.setState({loading: false})
        }
    }

    render() {
        if (this.state.active) {
            return (
                <div>
                    <Grid container className="configuracoes-perfil-sessao">
                        <Grid item sm={12} md={6}>
                            <h2 className="title-normal">{this.props.title}</h2> { this.props.field === 'pseudonimo' ? <h3 className="title-small color-red">Só é permitida uma troca a cada 90 dias.</h3> : ''}
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <div className="float-right">
                                <button className="text-small link-green" onClick={() => { this.setState({active: false, model: this.props.model}); }} >Cancelar</button>
                            </div>
                        </Grid>
                    </Grid>
                    
                    { 
                      this.props.field === 'telefone' ? 
                        <input type="tel" maxLength={15} className="input bg-white" value={this.state.model!} onChange={(e) => { this.setState({ model: this.handleTelefoneInvalido(e.target.value) }); }} />
                        :
                      this.props.field === 'bio' ? 
                        <div>
                          <textarea maxLength={150} autoFocus className="input bg-white textarea scroll" defaultValue={this.state.model!} onChange={(e) => this.setState({ model: e.target.value })}></textarea>
                            <p className="color-bright-gray text-default mt-10">Restam 
                            {
                              this.state.model ?  <span> {150 - (this.state.model!.length)} </span>  : <span> 150 </span>
                            }
                            caracteres</p>
                        </div>
                        :
                       this.props.field === 'sobre'? 
                        <div>
                          <textarea maxLength={400} autoFocus className="input bg-white textarea scroll" defaultValue={this.state.model!} onChange={(e) => this.setState({ model: e.target.value })}></textarea>
                          <p className="color-bright-gray text-default mt-10">Restam
                          {
                            this.state.model ? <span> {400 - (this.state.model!.length)}  </span>  : <span> 400 </span>
                          }
                          caracteres</p>
                        </div>
                        :
                        (
                            <input maxLength={this.props.maxlength} type={ this.selectFieldType() } autoFocus className="input bg-white" defaultValue={this.state.model!} onChange={(e) => this.setState({ model: e.target.value })} onKeyUp={(event) => this.handleSenhaInvalido(event)}/>
                        )
                    }

                    { this.props.field === 'senha' ?
                        <div>
                            <div className={(this.state.senhaInvalido ? "alterar-senha-invalid": "alterar-senha-valid")}>
                                <p className="message">A senha deve conter pelo menos 8 caracteres, ao menos 1 maiúsculo, 1 minúsculo e 1 dígito.</p>
                            </div>
                            <div>
                                <span className="forca-senha">{ this.state.model !== '' ? <Suspense fallback={renderLoader()}><LazyPasswordStrengthBar password={this.state.model} scoreWords={['fraco', 'fraco', 'ok', 'bom', 'forte']} minLength={8} shortScoreWord="muito curta"/></Suspense>: '' }</span>
                            </div>
                            <div className="mt-20">
                                <h2 className="title-normal mb-8">Confirmar Senha</h2>
                                <input type="password" className="input bg-white" value={this.state.confirmarsenha} onChange={(e) => this.setState({ confirmarsenha: e.target.value })} onKeyUp={(event) => this.handleConfirmarSenha(event)}/>
                                <p className={"message " + (this.state.senhavalida ? " invalid": " ")}>As senhas não conferem.</p>
                            </div>
                        </div>
                    :
                       <span></span>
                    }
                    <input type="submit" className={"btn bg-weak-green borderless color-white submit" + (this.state.loading ? " btn-disabled": "")} value="SALVAR" onClick={ this.handleSave }/>
                </div>
            );
        } else {
            return (
                <div>
                    <Grid container className="configuracoes-perfil-sessao">
		          		<Grid item sm={12} md={6}>
		          			<h2 className="title-normal">{this.props.title}</h2>
		          		</Grid>
		          		<Grid item sm={12} md={6}>
		          			<div className="float-right">
		          				<button className="text-small link-green" onClick={() => { this.setState({active: true}); }}>Editar</button>
		          			</div>
		          		</Grid>
                    </Grid>
                    <span className={"input bg-white disbled " +  (this.props.field === 'sobre' ? 'pre-line': '')}>{ this.state.model ? (this.props.field === 'senha' ? '*'.repeat(this.state.model.length) : this.state.model) : ''}</span>
                </div>
            );
        }
    }
}
export default withSnackbar(connector(ConfiguracoesPerfilItem))