import React, { Component } from "react";
import logo from "../../assets/icons/logo.svg";
import logoWhite from "../../assets/icons/logo-white.svg";
import UltimasLeis from './ultimas-leis';
import BuscaLegislacao from '../../components/busca-legislacao';
import { Container } from "@material-ui/core";
import { Grid, Box } from "@material-ui/core";
import {Helmet} from "react-helmet";

import './home.scss';

class HomePage extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Legispedia</title>
          <meta name="description" content="Uma ferramenta em constante construção, criada para expandir a capacidade dos usuários em lidar com a complexidade do sistema jurídico brasileiro." />
        </Helmet>

      <Container fixed>
        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <img src={logo} alt="Legispedia" className="logo"/>
            <h1 className="title title-home">Aqui, legislação vira <span className="color-orange d-block-desktop">conhecimento compartilhado</span></h1>
            <BuscaLegislacao />
          </Grid>
          <Grid item sm={12} md={6}>
              <UltimasLeis></UltimasLeis>
          </Grid>
        </Grid>
      </Container>

      <div className="bg-light-gray">
      <Container fixed>
        <Grid container spacing={3} className="home-sobre">
          <Grid item sm={12} md={8}>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <h2 className="title color-orange">Sobre a Legispedia</h2>
              <p className="color-bright-gray text-normal">A Legispedia é uma ferramenta em constante construção, criada para expandir a capacidade dos usuários em lidar com a complexidade do sistema jurídico brasileiro, estimulando o debate em um ambiente colaborativo, gratuito, confiável e organizado.</p>
              <br />
              <p className="color-bright-gray text-normal">É o primeiro passo de um projeto mais amplo que entende a necessidade de criar soluções tecnológicas que estimulem novas formas de estudar e entender o Direito.</p>
              <br />
              <p className="color-bright-gray text-normal">Aqui você poderá, por exemplo: (a) ler e comentar o texto da Lei; (b) avaliar os conteúdos postados; e (c) marcar seus conteúdos favoritos.</p>
            </Box>
          </Grid>
        </Grid>
        </Container>
      </div>

      <div className="footer bg-dark-gray">
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item sm={12} md={12}>
            <Box>
              <img src={logoWhite} alt="Legispedia" className="footer-img"/>
              <p><a href="mailto:contato@legispedia.com.br">contato@legispedia.com.br</a></p>
            </Box>
          </Grid>
        </Grid>
        </Container>
      </div>

      </div>
    );
  }
}
export default HomePage