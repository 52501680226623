import React, { Component } from 'react'
import * as Icon from 'react-feather';
import { Grid } from '@material-ui/core';
import API from 'API';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { environment } from '../../config/environment';

type MyProps = WithSnackbarProps & { lei: any, onSearchResults: (results: any, searchText: string) => void, onActiveChange: (active: boolean) => void, onMovimentaBusca: (index: number) => void, resultLength: number, resultIndex?: number };
type MyState = { searchOpen: any, searchText: string, loading: boolean };

class HeaderLegislacao extends Component<MyProps, MyState> {
    inputSearch: any;

    constructor(props: Readonly<MyProps>) {
        super(props);
        this.state = {searchOpen: false, searchText: '', loading: false};
        this.inputSearch = React.createRef();
        this.handleCancelSearch = this.handleCancelSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
    }

    handleChange(e: any) {
        let searchText: string = e.target.value;
        this.setState({searchText: searchText})
    }
    handleCancelSearch() {
        this.setState({searchOpen: !this.state.searchOpen, searchText: ''});
        this.props.onSearchResults([], '');
    }
    async handleSearchClick() {
        if(!this.state.searchOpen || this.state.searchText === '') {
            const active = !this.state.searchOpen;
            this.setState({searchOpen: active});
            this.props.onSearchResults([], '');
            this.inputSearch.current.focus();
            this.props.onActiveChange(active);
        } else {
            this.setState({loading: true});
            try
            {
                let searchResults = await API.get('/search?q=' + this.state.searchText + '&root=' + this.props.lei.id);
                this.props.onSearchResults(searchResults.data.result, this.state.searchText);
            }
            catch(e)
            {
                if(e.response) {
                  e.response.data.errors.forEach((element: any) => {
                      this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
                  });
                }
                else {
                  this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
                }
            }
            this.setState({loading: false});
        }
    }
    
    render() {
        return (
            <Grid className="legislacao-header" container direction="row" justify="space-around" alignItems="center">
                { this.state.searchOpen ? <Grid item sm={1} xs={1} container justify="center"><i className="icon-left color-weak-green cursor-pointer" onClick={this.handleCancelSearch}><Icon.ArrowLeft /></i></Grid> : '' }
                <Grid item sm={10} xs={10}>
                    <div className="legislacao-info">
                        <h2 className="text-slab color-dark-gray">{this.props.lei.nome}</h2>
                        <h3 className="color-clear-gray">{this.props.lei.texto}</h3>
                        <input type="text"
                               ref={this.inputSearch}
                               onKeyPress={(e: any) => { if(e.key === "Enter") { this.handleSearchClick(); }}}
                               placeholder="Buscar na legislação" 
                               className={ "has-icon-left has-icon-right " + ((this.state.searchOpen === null) ? '' : (this.state.searchOpen ? 'open' : 'closed'))}
                               value={this.state.searchText} onChange={(e) => this.handleChange(e)}/>
                    </div>
                </Grid>
                <Grid sm={1} xs={1} item container justify="center"><i className="icon-right color-weak-green cursor-pointer"><Icon.Search onClick={this.handleSearchClick} /></i></Grid>
                { this.props.resultIndex !== undefined && this.props.resultLength > 0 ? (
                <div className="legislacao-nav">
                    <span className="busca-nav-desc">Resultado {this.props.resultIndex + 1} de {this.props.resultLength}</span>
                    <span title="Anterior" onClick={() => { if (this.props.resultIndex !== undefined) this.props.onMovimentaBusca(this.props.resultIndex - 1) }}><Icon.ChevronUp /></span>
                    <span title="Próximo" onClick={() => { if (this.props.resultIndex !== undefined) this.props.onMovimentaBusca(this.props.resultIndex + 1) }}><Icon.ChevronDown /></span>
                </div>
                ) : "" } 
            </Grid>
        )
    }
}


export default withSnackbar(HeaderLegislacao)