import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import SideMenu from "components/side-menu";
import { Routes } from "./constants";
import "./scss/App.scss";
import { AppState } from "store/reducers";
import { ConnectedProps, connect } from "react-redux";
import API from "API";
import { REFRESH_PROFILE, UserProfile, SET_TOKEN, SET_ROLES } from "store/types/userProfile";
import { FETCH_LIKES } from "store/types/comentarios";
import { SIGN_IN } from "store/types/isLogged";
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { environment } from './config/environment';
import CookieConsent from "react-cookie-consent";
import {Helmet} from "react-helmet";

const mapState = (state: AppState) => ({});

const mapDispatch = {
  fetchLikes: (array: number[]) => ({ type: FETCH_LIKES, payload: array }),
  refreshProfile: (profile: UserProfile) => ({ type: REFRESH_PROFILE, payload: profile}),
  setRoles: (roles: string[]) => ({type: SET_ROLES, payload: roles}),
  setToken: (token: string) => ({ type: SET_TOKEN, payload: token }),
  signIn: () => ({ type: SIGN_IN })
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector> & WithSnackbarProps & {};

class App extends Component<PropsFromRedux> {
  async componentDidMount() {
    // Fluxo de preenchimento dos estados e validação do login do usuario inicial
    try {
      const token = localStorage.getItem('userToken');
      if(token) {
        let request = await API.get('/profile', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.props.setRoles(request.data.roles);
        this.props.refreshProfile(request.data.user);
        this.props.setToken(token);
        this.props.signIn();

        let likes = await API.get("/comentario/liked", {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        this.props.fetchLikes(likes.data.comentarios);
      }
    } catch (e) {
      if(e.response) {
        localStorage.removeItem('userToken');
      }
      else {
        this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
      }
    }
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>Legispedia</title>
          <meta name="description" content="Uma ferramenta em constante construção, criada para expandir a capacidade dos usuários em lidar com a complexidade do sistema jurídico brasileiro." />
           {/* inline script elements */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebSite",
                "url": "https://www.legispedia.com.br/",
                "potentialAction": {
                  "@type": "SearchAction",
                  "target": "https://www.legispedia.com.br/busca-legislacao?q={search_term_string}",
                  "query-input": "required name=search_term_string"
                }
              }
             `}
          </script>
        </Helmet>
        <Router>
          <SideMenu></SideMenu>

          <Switch>
            {Routes.map((route, index) => (
              // Render more <Route>s with the same paths as
              // above, but different components this time.
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={
                  <section className={route.class}>
                    <route.main />
                  </section>
                }
              />
            ))}
          </Switch>

          <CookieConsent
            location="bottom"
            buttonText="OK, ENTENDI"
            cookieName="LegispediaCookieConsent"
            style={{ background: "#5F7980", fontSize: "14px", fontFamily: "'Roboto Slab', serif", paddingLeft: "10px", fontWeight: "bold" }}
            buttonStyle={{ color: "white", fontSize: "14px", fontWeight: "bold", padding: "17px 24px", background: "#5F7980", borderRadius: "100px", border: "2px solid #fff" }}
            expires={150}
          >
            Esse site usa cookies para gerir, melhorar e personalizar sua experiência de navegação. Para mais informações sobre como nós lidamos com as informações coletadas, por favor leia nossa <Link to="/politica-de-privacidade" style={ {textDecoration: 'underline'} } >Política de Privacidade</Link>
          </CookieConsent>

        </Router>
      </div>
    );
  }
}

export default withSnackbar(connector(App))