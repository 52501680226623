import React, { Component } from "react";
import logo from "../../assets/icons/logo-big.svg";
import "./login.scss";
import "./aguardando-confirmacao-page.scss";
import { CircularProgress, Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ConnectedProps, connect } from "react-redux";
import { AppState } from "../../store/reducers";
import { environment } from "../../config/environment";
import API from "../../API";
import * as queryString from 'query-string';
import { REFRESH_PROFILE, SET_ROLES, SET_TOKEN, UserProfile } from "../../store/types/userProfile";
import {Helmet} from "react-helmet";

const mapState = (state: AppState) => {
  return ({
    isLogged: state.isLogged
  });
}

const mapDispatch = {
  signIn: () => ({ type: 'SIGN_IN' }),
  fetchLikes: (array: number[]) => ({type: 'FETCH_LIKES', payload: array}),
  refreshProfile: (profile: UserProfile) => ({ type: REFRESH_PROFILE, payload: profile }),
  setToken: (token: string) => ({type: SET_TOKEN, payload: token}),
  setRoles: (roles: string[]) => ({type: SET_ROLES, payload: roles}),
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>

type RouterProps = RouteComponentProps<{}>

type Props =  PropsFromRedux & WithSnackbarProps & RouterProps & {}

type MyState = {
  loading: boolean;
  token: string;
};

class ConfirmarPerfilPage extends Component<Props, MyState> {
  constructor(props: Readonly<Props>) {
    super(props);
    
    const qs = queryString.parse(this.props.history.location.search);
    if(this.props.isLogged || !qs.code)
        this.props.history.push("/");
    
    this.state = { 
      loading: true,
      token: qs.code as string
    };
}

  async componentDidMount() {
    document.body.classList.add("bg-light-gray");
    try {
      const confirmation = await API.post('/profile/confirmation', {
          token: this.state.token
      });
      const token = confirmation.data.token
      
      let likes = await API.get('/comentario/liked', {
        headers: {
          'Authorization': `Bearer ${token}`
        }});
    
      let request = await API.get('/profile', {
          headers: {
              'Authorization': `Bearer ${token}`
          }});
          
      localStorage.setItem("userToken", token);
      
      this.props.setToken(token);
      this.props.setRoles(request.data.roles);
      this.props.refreshProfile(request.data.user);
      this.props.signIn();
      this.props.fetchLikes(likes.data.comentarios);

      this.setState({ loading: false })
    } catch (e) {
      if(e.response) {
          e.response.data.errors.forEach((element: any) => {
              this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
          });
          this.setState({loading: false});
      }
      else {
      this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
      }
    }
  }

  render() {
    return (
      <div>
        <Helmet>
            <title>Conta confirmada com sucesso! - Legispedia</title>
            <meta name="description" content="Uma ferramenta em constante construção, criada para expandir a capacidade dos usuários em lidar com a complexidade do sistema jurídico brasileiro." />
        </Helmet>

        { this.state.loading ? <div className="legislacao-loading"><CircularProgress /></div> : (
            <Container fixed>
              <Grid container>
                <Grid item sm={12} md={12}>
                  <img src={logo} alt="Legispedia" className="login-logo" />

                  <div className="login-box">
                    <h1 className="celular-title">Conta confirmada com sucesso!</h1>
                    <Grid container>
                      <Grid item sm={12} md={8}>
                        <p className="text color-bright-gray text-default">
                          Aproveite para navegar pela plataforma e contribuir com os seus comentários.
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Container>
        ) }
      </div>
    );
  }
}

export default withSnackbar(withRouter(connector(ConfirmarPerfilPage)))