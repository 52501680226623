import * as React from "react";
import { Fragment, useState, useEffect } from 'react';
import { List, Datagrid, SimpleShowLayout, Show, TextField, useQuery, FormDataConsumer, TopToolbar, Loading, useDataProvider,
         Error, Edit, Create, SimpleForm, BooleanInput, TextInput, NumberInput, ReferenceInput, SelectInput, AutocompleteInput, } from 'react-admin'
import { Grid } from "@material-ui/core";
import { Typography, Button, makeStyles } from "@material-ui/core";
import { Column, Table} from 'react-virtualized';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import 'react-virtualized/styles.css'; // only needs to be imported once

const redirect = (basePath, id, data) => `/legislacao/${data.rootId}/show`;

export const LegislacaoList = (props) => (
    <List {...props} title="Legislações">
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="nome" />
            <TextField source="texto" />
        </Datagrid>
    </List>
);

const validateLeiEdition = (values) => {
    const errors = {};
    if (!values.nome) {
        errors.nome = ['Digite o nome'];
    }

    if (!values.texto) {
        errors.texto = ['Digite o texto'];
    }
    return errors
};

const optionRenderer = choice => `${choice.id} - ${choice.nome}`;

export const LegislacaoEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm validate={validateLeiEdition} redirect={redirect}>
            <TextInput source="id" disabled />
            <TextInput multiline source="nome" required fullWidth={true} />
            <TextInput multiline source="texto" required fullWidth={true}/>

            <Grid container spacing={3}>
              <Grid item sm={12} md={6}>
                <ReferenceInput source="tipoId" reference="tipos" fullWidth={true}><SelectInput optionText="tipo" /></ReferenceInput>
              </Grid>
              <Grid item sm={12} md={3}>
                <BooleanInput source="sumario" />
              </Grid>

              <Grid item sm={12} md={3}>
                <BooleanInput source="substituido" />
              </Grid>
            </Grid>

            <TextInput source="breadcrumb" fullWidth={true} />

            <Grid container spacing={3}>
              <Grid item sm={12} md={4}>
                <NumberInput source="ordem" fullWidth={true} />
              </Grid>
            </Grid>

            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <Fragment>
                        <div className="d-none">
                            <TextInput type="hidden" initialValue={formData.rootId} source="rootId"></TextInput>
                            <TextInput type="hidden" initialValue={formData.paiId} source="paiId"></TextInput>
                        </div>
                        <ReferenceInput fullWidth={true} source="origemId" reference="legislacao-referencia" filter={ { paiId: formData.paiId } } filterToQuery={searchText => ({ q: searchText })}><AutocompleteInput optionText={optionRenderer} /></ReferenceInput>
                    </Fragment>
                )}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
);

const validateLeiCreation = (values) => {
    const errors = {};
    if (!values.nome) {
        errors.nome = ['Digite o nome'];
    }

    if (!values.texto) {
        errors.texto = ['Digite o texto'];
    }
    return errors
};


export const LegislacaoCreate = props => {
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false);
    const [origem, setOrigem] = useState();
    const [error, setError] = useState();
    useEffect(() => {
        if(props.location.state && props.location.state.record && props.location.state.record.origemId) {
            setLoading(true);
            dataProvider.getOne('legislacao', { id: props.location.state.record.origemId })
            .then(({ data }) => {
                setOrigem(data)
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
        }
    }, [dataProvider, props.location.state]);
    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!origem && props.location.state && props.location.state.record && props.location.state.record.origemId) return null;

    return (
        <Create {...props}>
            <SimpleForm validate={validateLeiCreation} redirect={redirect}>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return (
                            <Fragment>
                                <TextInput multiline source="nome" required fullWidth={true} initialValue={origem ? origem.nome : null} className="textarea-multiline" />
                                <TextInput multiline source="texto" required options={{ multiLine: true }} fullWidth={true} className="textarea-multiline"/>

                                
                                <Grid container spacing={3}>
                                    <Grid item sm={12} md={6}>
                                        <ReferenceInput source="tipoId" reference="tipos" initialValue={origem ? origem.tipoId : null} fullWidth={true}><SelectInput optionText="tipo" /></ReferenceInput>
                                    </Grid>
                                    { formData.rootId ? 
                                        <Fragment>
                                            <Grid item sm={12} md={3}>
                                                <BooleanInput source="sumario" initialValue={origem ? origem.sumario : null} />
                                            </Grid>

                                            <Grid item sm={12} md={3}>
                                                <BooleanInput source="substituido" />
                                            </Grid>
                                        </Fragment>
                                        : null
                                    }
                                </Grid>

                                { formData.rootId ? 
                                        <Fragment>
                                            <TextInput source="breadcrumb" fullWidth={true} initialValue={origem ? origem.breadcrumb : null}/>
                                            <Grid container spacing={3}>
                                                <Grid item sm={12} md={4}>
                                                    <NumberInput source="ordem" fullWidth={true} initialValue={origem ? (origem.ordem + 1) : null}/>
                                                </Grid>
                                            </Grid>
                                            <div className="d-none">
                                                <TextInput type="hidden" initialValue={formData.rootId} source="rootId"></TextInput>
                                                <TextInput type="hidden" initialValue={origem ? origem.paiId : formData.paiId} source="paiId"></TextInput>
                                                <TextInput type="hidden" initialValue={origem ? formData.origemId : null} source="origemId"></TextInput>
                                            </div>
                                        </Fragment>
                                    :
                                        <Fragment>
                                            <Grid container spacing={3}>
                                                <Grid item sm={12} md={4}>
                                                    <NumberInput source="numero" fullWidth={true} />
                                                </Grid>
                                                <Grid item sm={12} md={4}>
                                                    <NumberInput source="ano" fullWidth={true} />
                                                </Grid>
                                            </Grid>
                                        </Fragment>
                                }           
                                
                            </Fragment>
                    )}}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
)};

const LegislacaoShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        { data ? (
            <Fragment>
                <CriarFilhoButton rootId={data.id} paiId={data.id} />
                <EditLegislacaoButton id={data.id} />
            </Fragment>
        ) : ''}
    </TopToolbar>
);

const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        color: '#3f51b5',
        '& svg': { color: '#3f51b5' }
    },
});

const CriarFilhoButton = ({rootId, paiId, showLabel}) => {
    const classes = useStyles();
    return <Button
        component={Link}
        className={classes.button}
        title="Criar Filho"
        to={{
            pathname: '/legislacao/create',
            state: { record: { rootId: rootId, paiId: paiId } },
        }}>
        <AddIcon /> { showLabel ?? 'Criar Filho' }
    </Button>
}
const EditLegislacaoButton = ({id, showLabel}) => {
    const classes = useStyles();
    return <Button
        component={Link}
        className={classes.button}
        title="Editar"
        to={{
            pathname: `/legislacao/${id}/edit`
        }}>
        <EditIcon />{ showLabel ?? 'Editar' }
    </Button>
}
const SubstituirLegislacaoButton = ({rootId, id, showLabel}) => {
    const classes = useStyles();
    return <Button
        component={Link}
        className={classes.button}
        title="Substituir"
        to={{
            pathname: '/legislacao/create',
            state: { record: { rootId: rootId, origemId: id } },
        }}>
        <CompareArrowsIcon />{ showLabel ?? 'Substituir' }
    </Button>
}

const ArvoreLegislacao = ({ record }) => {
    const { data, loading, error } = useQuery({ 
        type: 'tree',
        resource: 'legislacao',
        payload: { id: record.id }
    });

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;

    return (
        <div>
            <Typography variant="h6">{record.nome} - {record.texto}</Typography>
            <Table
                headerHeight={30}
                height={400}
                rowCount={data.list.length}
                rowGetter={({ index }) => data.list[index]}
                rowHeight={50}
                width={1500}
            >
                <Column label="ID" dataKey="id" width={50} />
                <Column label="Titulo" dataKey="nome" width={250} />
                <Column label="Ordem" dataKey="ordem" width={100} />
                <Column label="Conteúdo" dataKey="texto" width={1000} />
                <Column label="" dataKey="" width={40} cellRenderer={({rowData}) => (<div>{<CriarFilhoButton showLabel={false} rootId={data.root.id} paiId={rowData.id} />}</div>)} headerRenderer={() => (<div> </div>)}></Column>
                <Column label="" dataKey="" width={45} cellRenderer={({rowData}) => (<div>{<EditLegislacaoButton showLabel={false} id={rowData.id} />}</div>)} headerRenderer={() => (<div> </div>)}></Column>
                <Column label="" dataKey="" width={45} cellRenderer={({rowData}) => (<div>{<SubstituirLegislacaoButton showLabel={false} rootId={data.root.id} id={rowData.id} />}</div>)} headerRenderer={() => (<div> </div>)}></Column>
            </Table>
        </div>
    )
}

export const LegislacaoShow = (props) => (
    <Show {...props} actions={<LegislacaoShowActions />}>
        <SimpleShowLayout>
            <ArvoreLegislacao />
        </SimpleShowLayout>
    </Show>
);