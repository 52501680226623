import React, { Component, lazy, Suspense } from 'react';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import logo from "../../assets/icons/logo-big.svg";
import API from "../../API";
import './redefinir-senha.scss';
import { ConnectedProps, connect } from "react-redux";
import { AppState } from "store/reducers";
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { environment } from "config/environment";
import * as queryString from 'query-string';
import { Info } from 'react-feather';
import {Helmet} from "react-helmet";

  const LazyPasswordStrengthBar = lazy(() => import('react-password-strength-bar') );
  const renderLoader = () => <p>&nbsp;</p>;
  
  const mapState = (state: AppState) => ({
    isLogged: state.isLogged
  })
  
  const mapDispatch = {

  }
  
  const connector = connect(mapState, mapDispatch)
  type PropsFromRedux = ConnectedProps<typeof connector>
  
  type RouterProps = RouteComponentProps<{}>
  
  type Props = PropsFromRedux & WithSnackbarProps & RouterProps & {}
  
  type MyState = {
    loading: boolean;
    senha: string;
    confirmarsenha: string;
    senhaInvalido: boolean;
    confirmarSenhaInvalido: boolean;
    token: string;
  };
  
  class RedefinirSenhaPage extends Component<Props, MyState> {
    constructor(props: Readonly<Props>) {
        super(props);
        
        const qs = queryString.parse(this.props.history.location.search);
        if(this.props.isLogged || !qs.code)
            this.props.history.push("/");
        
            this.state = { 
          loading: false,
          senha: "",
          confirmarsenha: "",
          senhaInvalido: false,
          confirmarSenhaInvalido: false,
          token: qs.code as string
        };

        this.handleRedefinirSenha = this.handleRedefinirSenha.bind(this);
    }

    componentDidMount() {
      document.body.classList.add("bg-light-gray");
    }
    
    handleSenhaInvalido (e: any) {
      const senha = this.state.senha;
      const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  
      if(re.test(senha)){
        this.setState({senhaInvalido: false});
      }else{
        this.setState({senhaInvalido: true});
      }
    }
    
    async handleRedefinirSenha(e: any){
      e.preventDefault();
      if (this.state.senha !== this.state.confirmarsenha) {
        this.props.enqueueSnackbar('As senhas não conferem.', { variant: 'error' }) 
      } else {
        try {
          this.setState({ loading: true });
          await API.post('/email/reset', { token: this.state.token, senha: this.state.senha});
          this.props.enqueueSnackbar('Senha alterada com sucesso!.', { variant: 'success' });
          this.props.history.push('/');
          this.setState({loading: false });
        } catch (e) {
          if(e.response) {
            e.response.data.errors.forEach((element: any) => {
                this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
            });
          }
          else {
            this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
          }
          this.setState({loading: false});
        }
      }
    }

    handleConfirmarSenhaInvalido (e: any) {
      const senha = this.state.senha;
      const confirmarsenha = this.state.confirmarsenha;

      if(confirmarsenha === senha){
        this.setState({confirmarSenhaInvalido: false});
      }else{
        this.setState({confirmarSenhaInvalido: true});
      }
    }

    render() {
        return (
            <div className="redefinir-senha">
                <Helmet>
                    <title>Redefinir Senha - Legispedia</title>
                    <meta name="description" content="Uma ferramenta em constante construção, criada para expandir a capacidade dos usuários em lidar com a complexidade do sistema jurídico brasileiro." />
                </Helmet>

                <Container fixed>
                    <Grid container>
                        <Grid item xs={12}>
                            <img src={logo} alt="Legispedia" className="login-logo" />
                            <div className="login-box input-margin">
                                <h1 className="login-title">Redefinir Senha</h1>
                                <form name="form">
                                  <div className={"item-form " + (this.state.senhaInvalido ? " invalid": " ")}>
                                      <input type="password" placeholder="Nova senha" className="input" value={this.state.senha} onChange={(e) => this.setState({ senha: e.target.value })} onKeyUp={(event) => this.handleSenhaInvalido(event)} />
                                      <span className="forca-senha">{ this.state.senha !== '' ? <Suspense fallback={renderLoader()}><LazyPasswordStrengthBar password={this.state.senha} scoreWords={['fraco', 'fraco', 'ok', 'bom', 'forte']} minLength={8} shortScoreWord="muito curta"/></Suspense> : '' }</span>
                                      <p className="message">A senha deve conter pelo menos 8 caracteres, ao menos 1 maiúsculo, 1 minúsculo e 1 dígito.</p>
                                      <i className="icon-info">
                                        <Info />
                                        <div className="note-info">A senha deve conter pelo menos 8 caracteres, ao menos 1 maiúsculo, 1 minúsculo e 1 dígito.</div>
                                      </i>
                                    </div>
                                    <div className={"item-form " + (this.state.confirmarSenhaInvalido ? " invalid": " ")}>
                                      <input type="password" placeholder="Confirmar nova senha" className="input" value={this.state.confirmarsenha} onChange={(e) => this.setState({ confirmarsenha: e.target.value })} onKeyUp={(event) => this.handleConfirmarSenhaInvalido(event)}/>
                                      <p className="message">As senhas não conferem.</p>
                                      <input type="submit" className="input btn-submit bg-weak-green borderless color-white" value="ALTERAR" onClick={(e) => this.handleRedefinirSenha(e)} disabled={this.state.loading}/>
                                  </div>
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    }
  }

  export default withSnackbar(withRouter(connector(RedefinirSenhaPage)))