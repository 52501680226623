import React, { Component } from 'react'
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import placeholder from "../../assets/profile.png";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ConfiguracoesPerfilItem from './configuracoes-perfil-item';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from 'store/reducers';
import { SIGN_OUT } from 'store/types/isLogged';
import { UserProfile, REFRESH_PROFILE } from 'store/types/userProfile';
import { userProfileReducerDefaultState } from 'store/reducers/userProfile';
import './perfil-page.scss';
import './configuracoes-perfil-page.scss';
import API from 'API';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { environment } from '../../config/environment';
import * as Icon from 'react-feather';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import Modal from '@material-ui/core/Modal';
import { Helmet } from "react-helmet";


const mapState = (state: AppState) => ({
	isLogged: state.isLogged,
	profile: state.profile
})

const mapDispatch = {
	signOut: () => ({ type: SIGN_OUT }),
	refreshProfile: (profile: UserProfile) => ({ type: REFRESH_PROFILE, payload: profile })
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>

type RouterProps = RouteComponentProps<{}>

type Props = PropsFromRedux & RouterProps & WithSnackbarProps & {};
  
type MyState = { 
	novaSenha: string,
	crop: any,
	imgSrc: any,
	imgPixelCrop: any,
	solicitarAcreditado: boolean,
	motivoSolicitacao: string,
	loading: boolean
};

class ConfiguracoesPerfilPage extends Component<Props, MyState> {
	imageRef: any;
	constructor(props: Readonly<Props>) {
        super(props);
		this.state = { 
			novaSenha: '',
			crop:{
				unit: '%',
				width: 80,
				aspect: 1/1,
			},
			imgSrc: null,
			imgPixelCrop: null,
			solicitarAcreditado: false,
			motivoSolicitacao: '',
			loading: false
		};
		this.handleConfirmarEdicao = this.handleConfirmarEdicao.bind(this);
		this.handleUploadImage = this.handleUploadImage.bind(this);
		this.handleSolicitacaoAcreditado = this.handleSolicitacaoAcreditado.bind(this);
		this.handleCloseSolicitarAcreditado = this.handleCloseSolicitarAcreditado.bind(this);
		this.handleOpenSolicitarAcreditado = this.handleOpenSolicitarAcreditado.bind(this);
	}
	
	handleLogout() {
		localStorage.removeItem("userToken");
		this.props.signOut();
		this.props.refreshProfile(userProfileReducerDefaultState);
		this.props.history.push("/");
	}

	handleClick = (e:any) => {
		const inputElement = document.getElementById('inputElement') as HTMLInputElement
		if (inputElement) {
			inputElement.click();
		}
	}

	componentDidMount() {
		document.body.classList.add("bg-light-gray");
	}

	async handleConfirmarEdicao (model: any, field: string) : Promise<boolean> {
		let updatedField: any = {};
		updatedField[field] = model;

		try
		{
			let requestAtualizarPerfil = await API.put('/users/' + this.props.profile.id, updatedField,
			{
				headers: {
					Authorization: `Bearer ${this.props.profile.token}`
				}
			});
			this.props.refreshProfile(requestAtualizarPerfil.data.edit);
			return true;			
		}
		catch(e)
		{
			if(e.response) {
				e.response.data.errors.forEach((element: any) => {
					this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
				});
			}
			else {
				this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
			}
			return false;
		}
	}

	onSelectFile(event: any){
		if (event.target.files && event.target.files.length > 0) {
		  const reader = new FileReader();
		  reader.addEventListener('load', () =>
		    this.setState({ imgSrc: reader.result })
		  );
		  reader.readAsDataURL(event.target.files[0]);
		}
	};

	async handleUploadImage(event: any){

		let croppedImg = this.getCroppedImg(this.imageRef, this.state.crop);
		try
		{
			const pic = await API.put('/picture', {
				picture: croppedImg,

			},{
				headers: {
					'Authorization': `Bearer ${this.props.profile.token}`
				}
			});

			this.props.refreshProfile(pic.data.user);
			this.props.enqueueSnackbar('Foto de perfil atualizada.', { variant: 'success' });
			this.setState({imgSrc:null});
		}
		catch(e)
		{
		    if(e.response) {
		      e.response.data.errors.forEach((element: any) => {
		          this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
		      });
		    }
		    else {
		      this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
		    }
		}
	}

	handleOnCropChange = (crop: any) => {;
		this.setState({crop:crop})
	}

	handleImageLoaded = (image:any) =>{
		this.imageRef = image;
	}

	handleOnCropComplete = (crop:any, pixelCrop:any) =>{
		this.setState({crop: crop, imgPixelCrop: pixelCrop});
	}

	handleCloseSolicitarAcreditado() {
		this.setState({solicitarAcreditado: false, motivoSolicitacao: '' });
	}

	handleOpenSolicitarAcreditado() {
		this.setState({solicitarAcreditado: true});
	}

	async handleSolicitacaoAcreditado() {
	try
		{
			const solicitacaco = await API.post('/solicitacao', {
				motivo: this.state.motivoSolicitacao
			}, {
				headers: {
					'Authorization': `Bearer ${this.props.profile.token}`
				}
			});
			this.props.enqueueSnackbar(solicitacaco.data.message, { variant: 'success' });
			this.setState({solicitarAcreditado: false, motivoSolicitacao: '' })
		}
		catch(e)
		{
		    if(e.response) {
		      e.response.data.errors.forEach((element: any) => {
		          this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
		      });
		    }
		    else {
		      this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
		    }
		}
	}

	getCroppedImg(image:any, crop:any) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');
	  
		if (ctx) {
			ctx.drawImage(
				image,
				crop.x * scaleX,
				crop.y * scaleY,
				crop.width * scaleX,
				crop.height * scaleY,
				0,
				0,
				crop.width,
				crop.height,
			);
		}

		const base64Image = canvas.toDataURL('image/jpeg');
		return base64Image;
	}

    render() {
		const {imgSrc} = this.state
        return (
            <div>
                <Helmet>
 	               	<title>Configurações do Perfil - Legispedia</title>
					<meta name="description" content="A Legispedia é uma ferramenta em constante construção, criada para expandir a capacidade dos usuários em lidar com a complexidade do sistema jurídico brasileiro." />
                </Helmet>
 
		      <Container fixed>
		        <Grid container spacing={3}>
		          <Grid item sm={12} md={8} className="configuracoes-perfil-grid">

          			<div className="configuracoes-perfil-photo">
          				<div className="wrap-img wrap-img-80" style ={ { backgroundImage: "url("+ (this.props.profile.foto ? this.props.profile.foto : placeholder) +")" } }></div>
              			<button className="text-small link-green" onClick={this.handleClick}>Editar Foto</button>
              			<input type="file" className="d-none" id="inputElement" name="inputElement" onChange={ (event) => this.onSelectFile(event)}/>
              			
              			{imgSrc !== null ? 
              				<div>
							<Modal
								open={true}
								aria-labelledby="simple-modal-title"
								aria-describedby="simple-modal-description"
								className="modal-container">
								<div className="modal-box modal-mobile-small">
									<Icon.X className="color-clear-gray close-modal mb-8" onClick={() => this.setState({imgSrc:null})}/>
									<div className="mt-10">
		              					<ReactCrop src={imgSrc}
		              					crop={this.state.crop}
		              					onChange={this.handleOnCropChange}
		              					onImageLoaded={this.handleImageLoaded}
		              					onComplete={this.handleOnCropComplete}
		              					/>
		              				</div>
		              					<p className="text-center">
		              						<button className="btn bg-weak-green borderless color-white btn-modal" onClick={this.handleUploadImage}>Confirmar</button>						
										</p>
								</div>
							</Modal>
              				</div>
              				:''
              			}
              		
              		</div>

					<h1 className="title-medium title-main">Perfil: {this.props.profile.pseudonimo ? this.props.profile.pseudonimo : this.props.profile.nome}</h1>

					<div className="padding item-form">
						<ConfiguracoesPerfilItem maxlength={150} field='pseudonimo' title='Nome de usuário' model={this.props.profile.pseudonimo} onConfirmarEdicao={this.handleConfirmarEdicao} />
		          	
                        <i className="icon-info">
                            <Icon.Info />
                            <div className="note-info">O seu nome de usuário é uma alternativa ao seu nome completo caso você não deseje tornar seu nome público. Não é possível realizar login com seu nome de usuário, apenas com o seu e-mail</div>
                        </i>
		          	</div>

					<div className="border-top padding item-form">
						<ConfiguracoesPerfilItem field='bio' title='Bio' model={this.props.profile.bio} onConfirmarEdicao={this.handleConfirmarEdicao} />
		          	
                        <i className="icon-info">
                            <Icon.Info />
                            <div className="note-info">A sua bio é pública.</div>
                        </i>
		          	</div>
					
					<div className="border-top padding item-form">
						<ConfiguracoesPerfilItem field='sobre' title='Mini-Currículo' model={this.props.profile.sobre} onConfirmarEdicao={this.handleConfirmarEdicao} />
		          	
                        <i className="icon-info">
                            <Icon.Info />
                            <div className="note-info">O seu mini-currículo aparece para usuários acreditados.</div>
                        </i>
		          	</div>
		          	
		          	<h2 className="border-top title-medium title-sub">Informações pessoais</h2>

					<div className="padding item-form">
						<ConfiguracoesPerfilItem field='nome' title='Nome Completo' maxlength={150} model={this.props.profile.nome} onConfirmarEdicao={this.handleConfirmarEdicao} />
		          	
                        <i className="icon-info">
                            <Icon.Info />
                            <div className="note-info">O seu nome aparecerá para outros usuários apenas se o nome de usuário não estiver definido.</div>
                        </i>
		          	</div>
					
					<div className="border-top padding item-form">
						<ConfiguracoesPerfilItem field='telefone' title='Telefone' model={this.props.profile.telefone} onConfirmarEdicao={this.handleConfirmarEdicao} />
		          	
                        <i className="icon-info">
                            <Icon.Info />
                            <div className="note-info">O seu telefone não aparecerá para outros usuários.</div>
                        </i>
		          	</div>

					<div className="border-top padding item-form">
						<ConfiguracoesPerfilItem field='senha' title='Senha' model={this.state.novaSenha} onConfirmarEdicao={this.handleConfirmarEdicao} />

                        <i className="icon-info">
                            <Icon.Info />
                            <div className="note-info">A senha deve conter pelo menos 8 caracteres, ao menos 1 maiúsculo, 1 minúsculo e 1 dígito.</div>
                        </i>
		          	</div>
					{this.props.profile.roles.indexOf('Acreditado') !== -1 ? '' : 
						<button className="text-default btn btn-outline color-weak-green" onClick={this.handleOpenSolicitarAcreditado}>Solicitar perfil de acreditado</button>
					}
					
		          </Grid>
		        </Grid>
		      </Container>
            
			
			
			  <Modal
					open={this.state.solicitarAcreditado}
					onClose={this.handleCloseSolicitarAcreditado}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					className="modal-container">
					<div className="modal-box">
						<Icon.X className="color-clear-gray close-modal" onClick={this.handleCloseSolicitarAcreditado}/>
						<h2 className="title-medium title-modal">Solicitar perfil de acreditado</h2>

						<textarea maxLength={255} className="input textarea" placeholder="Escreva o motivo da sua solicitação" value={this.state.motivoSolicitacao} onChange={(e) => this.setState({ motivoSolicitacao: e.target.value })}></textarea>
                        <p className="color-bright-gray text-default mt-10">Restam 
                        {
                          this.state.motivoSolicitacao ?  <span> {255 - (this.state.motivoSolicitacao!.length)} </span>  : <span> 255 </span>
                        }
                        caracteres</p>
					<button className={"btn bg-weak-green borderless color-white btn-modal" + (this.state.loading ? " btn-disabled": "")} onClick={this.handleSolicitacaoAcreditado}>Enviar solicitação</button>
					</div>
				</Modal>
			</div>
        )
    }
}

export default withSnackbar(withRouter(connector(ConfiguracoesPerfilPage)))