import * as React from "react";
import { List, Datagrid, ReferenceField, DateField, TextField, Button, useMutation, useRefresh } from 'react-admin'
import { Filter, SearchInput } from 'react-admin';
import { makeStyles, Chip } from '@material-ui/core';

const ApproveButton = ({ record }) => {
    const refresh = useRefresh();
    const [approve, { loading, data }] = useMutation({
        type: 'update',
        resource: 'denuncia',
        payload: { id: record.id, data: { status: 'Aprovada' } }
    });
    
    if(data) refresh();

    if(record.status !== 'Aguardando Avaliação')
        return null;

    return <Button label="Aprovar" redirect="list" color="primary" onClick={approve} disabled={loading} />;
};

const RejectButton = ({ record }) => {
    const [approve, { loading }] = useMutation({
        type: 'update',
        resource: 'denuncia',
        payload: { id: record.id, data: { status: 'Rejeitada' } }
    });

    if(record.status !== 'Aguardando Avaliação')
        return null;
        
    return <Button label="Rejeitar" redirect="list" color="secondary" onClick={approve} disabled={loading} />;
};


const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ label }) => {
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={label} />;
};
const DenunciasFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <QuickFilter source="aprovadas" label="Aprovadas" defaultValue={true} />
        <QuickFilter source="rejeitadas" label="Rejeitadas" defaultValue={true} />
        <QuickFilter source="status" label="Aguardando Avaliação" defaultValue={'Aguardando Avaliação'} />
    </Filter>
);

const ComentarioPanel = ({ id, record, resource }) => (
    <div dangerouslySetInnerHTML={{ __html: record.Comentario.texto }} />
);

const LinkLegislacao = ({ record }) => {
    if(!record.Comentario) return null;

    let link = '';
    if (record.Comentario.Legislaco){
        if (record.Comentario.Comentario == null) {
            link = `/legislacao/${record.Comentario.Legislaco.rootId}/${record.Comentario.Legislaco.id}`
        } else {
            link = `/legislacao/${record.Comentario.Comentario.Legislaco.rootId}/${record.Comentario.Comentario.Legislaco.id}`
        }    
    }
    return <a href={link} target="_blank" rel="noopener noreferrer">Visualizar Comentário</a>
};

export const DenunciasList = props => (
    <List {...props} filters={<DenunciasFilter />} filterDefaultValues={{ status: 'Aguardando Avaliação' }} sort={{ field: 'createdAt', order: 'ASC' }}>
        <Datagrid expand={<ComentarioPanel />} >
            <TextField source="motivo" label="Motivo" />
            <ReferenceField source="userId" link="show" label="Usuário" reference="users"><TextField source="nome" /></ReferenceField>
            <DateField source="createdAt" label="Criado em" locales="pt-BR" />
            <TextField source="status" label="Status" />
            <LinkLegislacao />
            <ApproveButton />
            <RejectButton />
        </Datagrid>
    </List>
);