import React, { Component } from 'react'
import { Container, Button } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import placeholder from "../../assets/profile.png";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as Icon from 'react-feather';
import API from "../../API";
import './perfil-page.scss';
import Comentario from '../../components/comentario';
import { Link } from "react-router-dom";
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from 'store/reducers';
import { SIGN_OUT } from 'store/types/isLogged';
import { UserProfile, REFRESH_PROFILE, SET_ROLES } from 'store/types/userProfile';
import { userProfileReducerDefaultState } from 'store/reducers/userProfile';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { environment } from '../../config/environment';
import InfiniteScroll from 'react-infinite-scroller';
import { Helmet } from "react-helmet";

const mapState = (state: AppState) => ({
	isLogged: state.isLogged,
	profile: state.profile
  })
  
  const mapDispatch = {
	signOut: () => ({ type: SIGN_OUT }),
	refreshProfile: (profile: UserProfile) => ({ type: REFRESH_PROFILE, payload: profile }),
	setRoles: (roles: string[]) => ({type: SET_ROLES, payload: roles})
  }
  
  const connector = connect(mapState, mapDispatch)
  type PropsFromRedux = ConnectedProps<typeof connector>
  
  type RouterProps = RouteComponentProps<{}>
  
  type Props = PropsFromRedux & RouterProps & WithSnackbarProps & {}
  
type MyState = { comentarios: any, hasMoreItems: boolean, loading: boolean };

class PerfilPage extends Component<Props, MyState> {

	constructor(props: Readonly<Props>) {
        super(props);
		this.state = { comentarios: [], hasMoreItems: true, loading: false };

		this.loadComentarios = this.loadComentarios.bind(this);
	}
	
	handleLogout() {
		localStorage.removeItem("userToken");
		this.props.signOut();
		this.props.refreshProfile(userProfileReducerDefaultState);
		this.props.history.push("/");
	}

	handleBreadcrumb(breadcrumb: string) {
		return breadcrumb.split(' £ ').join(' | ')
	}

	async componentDidMount() {
		document.body.classList.add("bg-light-gray");
		
		if(!this.props.isLogged)
			this.props.history.push('/login');

		try
		{	
			let request = await API.get('/profile', {
				headers: {
					'Authorization': `Bearer ${this.props.profile.token}`
				}});
			this.props.setRoles(request.data.roles);
			this.props.refreshProfile(request.data.user);
			// this.loadComentarios(0);
		}
		catch(e)
		{
		    if(e.response) {
		      e.response.data.errors.forEach((element: any) => {
		          this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
		      });
		    }
		    else {
		      this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
		    }
		}
	}

	async loadComentarios(page: number) {
		try {
			// this.setState({loading: true})
			let requestComentarios = await API.get('/comentario/profile?page=' + page, {
				headers: {
					'Authorization': `Bearer ${this.props.profile.token}`
				}});
			const newArray = requestComentarios.data.comentarios;
			const comentariosArray = this.state.comentarios.concat(newArray);
			this.setState({comentarios: comentariosArray, hasMoreItems: (newArray.length === 10)});
		} catch (e) {
			if(e.response) {
				e.response.data.errors.forEach((element: any) => {
					this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
				});
			  }
			  else {
				this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
			  }
		}
		// this.setState({loading: false})
	}

    render() {
        return (
            <div>
                <Helmet>
 	               	<title>Perfil - Legispedia</title>
					<meta name="description" content="A Legispedia é uma ferramenta em constante construção, criada para expandir a capacidade dos usuários em lidar com a complexidade do sistema jurídico brasileiro." />
                </Helmet>

		      <Container fixed>
		        <Grid container spacing={3}>
		          <Grid item sm={12} md={8} className="w-100-mobile">

		          <div className="box-border no-border-bottom bg-white">
			          	<Grid container>
			          		<Grid item xs={6} md={6}>
			          			<div className="perfil-photo">
			          				<div className="wrap-img wrap-img-80" style ={ { backgroundImage: "url("+ (this.props.profile.foto ? this.props.profile.foto : placeholder) +")" } }></div>
			              		</div>
			          		</Grid>
			          		<Grid item xs={6} md={6}>
			          			<div className="perfil-config text-default">
									<Link to="/" onClick={() => this.handleLogout()}><span>Sair</span> <Icon.LogOut /></Link>
									<Link to="/configuracoes-perfil"><span>Configurações</span> <Icon.Settings /></Link>
			              		</div>
			          		</Grid>
			          	</Grid>
						<h1 className="comment-title perfil-nome">{this.props.profile.pseudonimo ? this.props.profile.pseudonimo : this.props.profile.nome} { this.props.profile.roles.indexOf('Acreditado') !== -1 ? <span className="comment-title-tag space color-orange">ACREDITADO</span> : '' }</h1>
						{ this.props.profile.bio && <p className="color-clear-gray text-default">{this.props.profile.bio}</p>}
					</div>
					<div className="box-border bg-white">
		            	<h2 className="color-orange title-slab">Meus comentários</h2>
		            </div>
					{ this.state.comentarios ? (
						<InfiniteScroll
							pageStart={-1}
							loadMore={this.loadComentarios}
							hasMore={this.state.hasMoreItems}
							loader={<div>carregando...</div>}>
							<div>
									{this.state.comentarios.map((lei: any) => (
										<div key={lei.id}>
											<h3 className="title-meus-comentarios color-bright-gray title-slab box-border no-border-bottom bg-white"><Link to={`/legislacao/${lei.rootId}/${lei.id}`}>{this.handleBreadcrumb(lei.breadcrumb)}</Link></h3>
											{ lei.Comentarios.map((item: any) => (
												<div className="box-border no-border-bottom bg-white perfil-item-comentario">
													<Comentario key={item.id} comentario={item} allowResponse={false} legislacaoId={lei.id} artigoId={lei.rootId}></Comentario>
													<div className="respostas">
														{item.respostas.map((resposta: any) => (
														<div key={resposta.id} ref={resposta.id}>
															<Comentario comentario={resposta} allowResponse={false} legislacaoId={lei.id} artigoId={lei.rootId}></Comentario>
														</div>
														))}
													</div>
												</div>
											))}
										</div>
									))}
								</div>
						</InfiniteScroll>
					) : '' }
		            
		            
		          </Grid>
				  <Grid container spacing={3}>
					  <Button onClick={() => this.handleLogout()}>&nbsp;</Button>
				  </Grid>
		        </Grid>
		      </Container>
            </div>
        )
    }
}

export default withSnackbar(withRouter(connector(PerfilPage)))