import React, { Component } from 'react'
import * as Icon from 'react-feather';
import { Grid } from '@material-ui/core';
import { tabSelectedTypes } from '../../constants';

type MyProps = { tab?: tabSelectedTypes, tabChanged?: ((tab: tabSelectedTypes) => void), activeOnlyChanged: ((active: boolean) => void), activeOnly: boolean };
type MyState = { };


export default class FooterLegislacao extends Component<MyProps, MyState> {


    handleTabChange(tab: tabSelectedTypes) {
        if(this.props.tabChanged)
            this.props.tabChanged(tab);        
    }
    handleActiveOnlyToogle() {
        var result = !this.props.activeOnly;
        this.props.activeOnlyChanged(result);
    }
    
    render() {
        return (
            <Grid className="rodape" container direction="row" justify="space-around" alignItems="center">
                <Grid item sm={1} xs={2} container justify="center">
                    <i className={"icon-left " + (this.props.activeOnly ? "active" : "")}
                       onClick={() => this.handleActiveOnlyToogle()} title="Visualizar somente legislação vigente">
                        <Icon.Eye />
                    </i>
                </Grid>
                <Grid item sm={1} xs={2} container justify="center">
                    <i className={"icon-left " + (this.props.tab === "text-view" ? "active" : "")}
                       onClick={() => this.handleTabChange("text-view")} title="Visualizar no modo texto">
                        <Icon.Type />
                    </i>
                </Grid>
                <Grid item sm={10} xs={8} container justify="flex-end">
                    <i className={"icon-right " + (this.props.tab === "summary" ? "active" : "")}
                       onClick={() => this.handleTabChange("summary")} title="Visualizar sumário">
                        {this.props.tab !== "summary" ? <Icon.List /> : <Icon.X />}
                    </i>
                </Grid>
            </Grid>
        )
    }
}
