import React , { Component } from 'react';
import * as Icon from 'react-feather';
import { Editor, EditorState, RichUtils, ContentState, Modifier, SelectionState, CompositeDecorator } from 'draft-js';
import { stateToHTML } from "draft-js-export-html";
import 'draft-js/dist/Draft.css';
import placeholder from "../assets/profile.png";
import htmlToDraft from 'html-to-draftjs';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from 'store/reducers';
import API from "../API";
import OrderedList from '../assets/icons/ordered-list.svg';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { environment } from '../config/environment';

const mapState = (state: AppState) => ({
	profile: state.profile
})
  
const mapDispatch = { }
  
const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
type MyProps = { edicaoComentario?: any, closeEditorClick: () => void, onSendComentario: (comentario: string) => void };
type Props = PropsFromRedux & WithSnackbarProps & MyProps
  
type MyState = { 
  editorState: EditorState,
  modalLink: boolean,
  modalAt: boolean,
  boxAt: boolean,
  enderecoLink: string,
  mencionarLink: string,
  disabled: boolean,
  searchResults: any
};

class ComentarioEditor extends Component<Props, MyState> {
    domEditor: any;

    findLinkEntities(contentBlock: any, callback: any, contentState: any) {
      contentBlock.findEntityRanges(
        (character: any) => {
          const entityKey = character.getEntity();
          return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === 'LINK'
          );
        },
        callback
      );
    }

    styles = {
      link: {
        color: '#3b5998',
        textDecoration: 'underline',
      },
    };

    Link = (props: any) => {
      const { url, className } = props.contentState.getEntity(props.entityKey).getData();
      return (
        <a href={url} target='_blank' className={className} rel="noopener noreferrer" style={this.styles.link}>
          {props.children}
        </a>
      );
    };

    decorator = new CompositeDecorator([
      {
        strategy: this.findLinkEntities,
        component: this.Link,
      },
    ]);

    constructor(props: Props) {
        super(props);
        if(this.props.edicaoComentario === undefined)
        {
          this.state = {
            editorState: EditorState.createEmpty(this.decorator),
            modalLink: false,
            modalAt: false,
            boxAt: false,
            enderecoLink: 'http://www.',
            mencionarLink: '',
            disabled: false,
            searchResults: [],
          };
        } else {
          const blocksFromHtml = htmlToDraft(this.props.edicaoComentario.texto);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
          this.state = {
            editorState: EditorState.createWithContent(contentState, this.decorator),
            modalLink: false,
            modalAt: false,
            boxAt: false,
            enderecoLink: '',
            mencionarLink: '',
            disabled: false,
            searchResults: [],
          };
        }
        
        this.setDomEditorRef = this.setDomEditorRef.bind(this);
        this.handleSend = this.handleSend.bind(this);
        this.handleClickLink = this.handleClickLink.bind(this);
        this.handleClickAt = this.handleClickAt.bind(this);
        this.handleAddLink = this.handleAddLink.bind(this);
        this.handleMencionar = this.handleMencionar.bind(this);
        this.buscarUsuario = this.buscarUsuario.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    async handleSend() {

      this.setState({ disabled: true });

      const options = {
        entityStyleFn: (entity: any) => {
          const entityType = entity.get('type').toLowerCase();
          if (entityType === 'link') {
            const data = entity.getData();
            return {
              element: 'a',
              attributes: {
                href: data.url,
                target:'_blank',
                class: data.className
              },
            };
          } 
        }
      };
      await this.props.onSendComentario(stateToHTML(this.state.editorState.getCurrentContent(), options));
      this.setState({ disabled: false });
    }

    setDomEditorRef(ref: any) { 
      if(ref) {
        this.domEditor = ref; 
        this.domEditor.focus();
      }
    }

    _onBoldClick(event: any) {
        event.preventDefault();
        this.onEditorStateChange(RichUtils.toggleInlineStyle(
          this.state.editorState,
          'BOLD'
        ));
    }
  
    _onItalicClick(event: any) {
        event.preventDefault();
        this.onEditorStateChange(RichUtils.toggleInlineStyle(
          this.state.editorState,
          'ITALIC'
        ));
    }
  
   _onUnderlineClick(event: any) {
        event.preventDefault();
        this.onEditorStateChange(RichUtils.toggleInlineStyle(
          this.state.editorState,
          'UNDERLINE'
        ));
    }
  
   _onUnorderedListClick(event: any) {
        event.preventDefault();
        this.onEditorStateChange(RichUtils.toggleBlockType(
          this.state.editorState,
          'unordered-list-item'
        ));
    }
  
   _onOrderedListClick(event: any) {
        event.preventDefault();
        this.onEditorStateChange(RichUtils.toggleBlockType(
          this.state.editorState,
          'ordered-list-item'
        ));
    }

    _onTab(event: any) {
      const maxDepth = 4;
      this.onEditorStateChange(RichUtils.onTab(event, this.state.editorState, maxDepth));
    }

    onEditorStateChange(editorState: EditorState) {
        const contentState = editorState.getCurrentContent();
        const oldContent = this.state.editorState.getCurrentContent();
        if(contentState === oldContent || contentState.getPlainText().length <= 2500) {
            this.setState({ editorState });
        } else {
            const editorState = EditorState.undo(
                EditorState.push(
                    this.state.editorState,
                    ContentState.createFromText(oldContent.getPlainText()),
                    'delete-character'
                )
            );
            this.setState({ editorState });
        }

    }

    handleClickLink() {
      this.setState({modalLink: !this.state.modalLink, modalAt: false, boxAt: false });
    }

    handleClickAt() {
      this.setState({modalAt: !this.state.modalAt, modalLink: false });
    }


    handleAddLink() {
      if(this.state.enderecoLink && this.state.enderecoLink !== '')
      {
        const editorState = this.state.editorState;
        const contentState = editorState.getCurrentContent();
        const selection = editorState.getSelection();

        const anchor = editorState.getSelection().getAnchorOffset();
        const focus = editorState.getSelection().getFocusOffset();

        if(anchor !== focus){

          const contentStateWithEntity = contentState.createEntity(
            'LINK',
            'MUTABLE',
            {url: this.state.enderecoLink}
          );
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
          const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
          this.setState({
            editorState: RichUtils.toggleLink(
            newEditorState,
            newEditorState.getSelection(),
            entityKey
          )})

        }else{
          // create new content with text
          const newContent = Modifier.insertText(
            contentState,
            selection,
            this.state.enderecoLink,
          );
          // create new link entity
          const newContentWithEntity = newContent.createEntity(
            'LINK',
            'MUTABLE',
            { url: this.state.enderecoLink }
          );
          const entityKey = newContentWithEntity.getLastCreatedEntityKey();
          // create new selection with the inserted text
          const anchorOffset = selection.getAnchorOffset();
          const newSelection = new SelectionState({
            anchorKey: selection.getAnchorKey(),
            anchorOffset,
            focusKey: selection.getAnchorKey(),
            focusOffset: anchorOffset + this.state.enderecoLink.length,
          });
          // and aply link entity to the inserted text
          const newContentWithLink = Modifier.applyEntity(
            newContentWithEntity,
            newSelection,
            entityKey,
          );
          // create new state with link text
          const withLinkText = EditorState.push(
            editorState,
            newContentWithLink,
            'insert-characters',
          );
          // now lets add cursor right after the inserted link
          const withProperCursor = EditorState.forceSelection(
            withLinkText,
            newContent.getSelectionAfter(),
          );
          // update the editor with all changes
          this.setState({editorState: withProperCursor});
          } 

          this.setState({modalLink: false, modalAt: false, enderecoLink: '', mencionarLink: ''});       
        }
    }

    handleMencionar(item: any) {

        let nome = `@${item.nome} `;

        const editorState = this.state.editorState;
        const contentState = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        // create new content with text
        const newContent = Modifier.insertText(
          contentState,
          selection,
          nome
        );

        // create new link entity
        const newContentWithEntity = newContent.createEntity(
          'LINK',
          'MUTABLE',
          { url: `#${item.id}`, className: 'mention' }
        );
        const entityKey = newContentWithEntity.getLastCreatedEntityKey();
        // create new selection with the inserted text
        const anchorOffset = selection.getAnchorOffset();
        const newSelection = new SelectionState({
          anchorKey: selection.getAnchorKey(),
          anchorOffset,
          focusKey: selection.getAnchorKey(),
          focusOffset: anchorOffset + (nome.length - 1),
        });
        // and aply link entity to the inserted text
        const newContentWithLink = Modifier.applyEntity(
          newContentWithEntity,
          newSelection,
          entityKey,
        );
        // create new state with link text
        const withLinkText = EditorState.push(
          editorState,
          newContentWithLink,
          'insert-characters',
        );
        // now lets add cursor right after the inserted link
        const withProperCursor = EditorState.forceSelection(
          withLinkText,
          newContent.getSelectionAfter(),
        );

        // update the editor with all changes
        this.setState({editorState: withProperCursor, modalLink: false, modalAt: false, enderecoLink: '', mencionarLink: '', searchResults: []});
    }

    async buscarUsuario(event: any){
      let termo = event.target.value;
      this.setState({ mencionarLink: event.target.value })

      if(termo.length > 2){
        this.setState({boxAt: true });

        try {
          const request = await API.get('/search-usuarios?q=' + termo);
          this.setState({searchResults: request.data.result});

          } catch (e) {

            if(e.response) {
              localStorage.removeItem('userToken');
            }
            else {
              this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
            }
        }


      }else{
        this.setState({boxAt: false });
      }
    }

    checkType(){
      if(this.state.editorState.getCurrentContent().getBlockForKey(this.state.editorState.getCurrentContent().getSelectionBefore().getStartKey()) !== undefined){ 
        return this.state.editorState.getCurrentContent().getBlockForKey(this.state.editorState.getCurrentContent().getSelectionBefore().getStartKey()).getType() 
      }else{
        return " ";
      }
    }

    handleKeyDown(event: any) {
      let charCode = String.fromCharCode(event.which).toLowerCase();
      if(event.ctrlKey && charCode === 'b') {
        this._onBoldClick(event);

      } else if(event.ctrlKey && charCode === 'i') {
        this._onItalicClick(event);

      } else if(event.ctrlKey && charCode === 'u') {
        event.preventDefault();
        this._onUnderlineClick(event);
      }

      // For MAC we can use metaKey to detect cmd key

      if(event.metaKey && charCode === 'b') {
        this._onBoldClick(event);

      } else if(event.metaKey && charCode === 'i') {
        this._onItalicClick(event);

      } else if(event.metaKey && charCode === 'u') {
        event.preventDefault();
        this._onUnderlineClick(event);
      }
    }
    render() {
        return (
            <div className="comment-editor">
                <Icon.X className="color-clear-gray close" onClick={ this.props.closeEditorClick }/>
                <div className="comment-header">
                    <h3 className="comment-title">
                        <div className="wrap-img wrap-img-36" style={{ backgroundImage: "url(" + (this.props.profile.foto ? this.props.profile.foto : placeholder) + ")" }}></div>
                        { this.props.profile.pseudonimo }
                    </h3>
                </div>
                <div className="comment-editor-header overflow-auto">
                  <div className="float-left">
                    <button title="Negrito" onMouseDown={this._onBoldClick.bind(this)} className={ this.state.editorState.getCurrentInlineStyle().has('BOLD') ? 'active' : ''}><Icon.Bold /></button>
                    <button title="Itálico" onMouseDown={this._onItalicClick.bind(this)} className={ this.state.editorState.getCurrentInlineStyle().has('ITALIC') ? 'active' : ''}><Icon.Italic /></button>
                    <button title="Sublinhado" onMouseDown={this._onUnderlineClick.bind(this)} className={ this.state.editorState.getCurrentInlineStyle().has('UNDERLINE') ? 'active' : ''}><Icon.Underline /></button>
                    <button 
                    title="Lista" 
                    onMouseDown={this._onUnorderedListClick.bind(this)} 
                    className={ this.checkType() === 'unordered-list-item' ? 'active' : ''}>
                    <Icon.List />
                    </button>
                    <button title="Lista Númerada" onMouseDown={this._onOrderedListClick.bind(this)} className={ this.checkType() === 'ordered-list-item'? 'active' : ''}><img src={OrderedList} alt="Lista Númerada" /></button>
                  </div>
                  <div className="float-right">
                    <button title="Marcar Usuário" onClick={this.handleClickAt}><Icon.AtSign /></button>
                    <button title="Criar Hyperlink" onClick={this.handleClickLink}><Icon.Link /></button>
                  </div>
                </div>
                { this.state.modalLink ? (
                  <div className="comment-link">
                    <input autoFocus type="text" className="input" placeholder="Endereço do hyperlink" value={this.state.enderecoLink} onChange={(e) => this.setState({ enderecoLink: e.target.value })}/>
                    <button className="btn bg-weak-green borderless color-white" onClick={this.handleAddLink}>OK</button>
                  </div>
                ) : '' 
                } 
                { this.state.modalAt ? (
                  <div className="comment-link">
                    <input autoFocus type="text" className="input" placeholder="Digite o nome de quem deseja mencionar" value={this.state.mencionarLink} onChange={ (event) => this.buscarUsuario(event) }/>
                    { this.state.boxAt ? (
                    <ul className="box-at text-default color-dark-green scroll">

                      { this.state.searchResults.length <= 0 ?(
                        <p>Nenhum resultado encontado</p>
                      ) :
                      
                          this.state.searchResults.map((item: any, index: number) => (
                          <li key={item.id} onClick={() => this.handleMencionar(item)}>
                            <div className="wrap-img wrap-img-36" style={{ backgroundImage: "url(" + (item.foto ? item.foto : placeholder) + ")" }}></div>
                            <span>{item.nome}</span>
                          </li>))                          
                      
                      }

                      </ul>
                      ) : '' 
                    } 
                  </div>
                ) : '' 
                } 
                <div className="comment-editor-textarea" onKeyDown={(e) => {this.handleKeyDown(e)}}>
                    <Editor 
                        editorState={this.state.editorState}
                        onChange={this.onEditorStateChange.bind(this)}
                        placeholder={ stateToHTML(this.state.editorState.getCurrentContent()) === '<p><br></p>' ? 'Escreva aqui seu comentário' : ''}
                        ref={this.setDomEditorRef}
                        onTab={this._onTab.bind(this)}
                    />
                </div>
                <p className="color-bright-gray text-default caracteres-restantes">Restam 
                {
                  this.state.editorState.getCurrentContent().getPlainText('').length ?  <span> {2500 - (this.state.editorState.getCurrentContent().getPlainText('').length)} </span>  : <span> 2500 </span>
                }
                caracteres</p>
                <div className="comment-editor-submit">
                    <button className={"btn bg-weak-green borderless color-white" + (this.state.disabled ? " btn-disabled" : "")} onClick={ this.handleSend }>Postar</button>
                </div>
            </div>
        );
    }
}

export default withSnackbar(connector(ComentarioEditor));