import React, { Component } from "react";
import logo from "../../assets/icons/logo-big.svg";
import './login.scss';
import './aguardando-confirmacao-page.scss';
import { Box, Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ConnectedProps, connect } from "react-redux";
import { AppState } from "store/reducers";
import { environment } from "config/environment";
import API from "API";
import {Helmet} from "react-helmet";

const mapState = (state: AppState) => ({
  isLogged: state.isLogged
})

const mapDispatch = {
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>

type RouterProps = RouteComponentProps<{}>

type Props =  PropsFromRedux & WithSnackbarProps & RouterProps & {}

type MyState = {
  username: string;
  loading: boolean;
};

class ReenviarConfirmacaoPage extends Component<Props, MyState>{

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = { username: "", loading: false };
    if(this.props.isLogged)
        this.props.history.push("/");

    this.handleSubmit = this.handleSubmit.bind(this)
  }

	componentDidMount() {
		document.body.classList.add("bg-light-gray");
	}

  async handleSubmit(e: any) {
    e.preventDefault();
    this.setState({ loading: true });
    const { username } = this.state;

    try {
        await API.post('/email/confirmation', {
            email: username
        });
        this.props.enqueueSnackbar('E-mail de confirmação enviado.', { variant: 'success' })
    } catch (e) {
      if(e.response) {
          e.response.data.errors.forEach((element: any) => {
              this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
          });
          this.setState({loading: false});
      }
      else {
      this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
      }
    }
  }

  render() {
      return (
      <div>
        <Helmet>
            <title>Confirmação de cadastro - Legispedia</title>
            <meta name="description" content="Uma ferramenta em constante construção, criada para expandir a capacidade dos usuários em lidar com a complexidade do sistema jurídico brasileiro." />
        </Helmet>

        <Container fixed>
          <Grid container>
            <Grid item sm={12} md={12}>
              <img src={logo} alt="Legispedia" className="login-logo"/>

              <div className="login-box">
                <h1 className="celular-title">Confirmação de cadastro</h1>
                <Grid container>
                  <Grid item sm={12}>
                      <p className="text color-bright-gray text-default">Preencha seu e-mail usado no momento do cadastro para encaminharmos um novo e-mail de confirmação:</p>
                  </Grid>
                </Grid>
                <Box mt={2}>
                  <form name="form" onSubmit={(e) => this.handleSubmit(e)}>
                      <input type="email" placeholder="Email" className="input" value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} />
                      
                      <input type="submit" className="input bg-weak-green borderless color-white submit" value="Reenviar Mensagem" disabled={this.state.loading}/>
                  </form>
                </Box>
              </div>	
            </Grid>
          </Grid>
        </Container>
          </div>
      )
  }
}

export default withSnackbar(withRouter(connector(ReenviarConfirmacaoPage)))
