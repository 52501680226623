import React, { Component } from 'react'
import * as Icon from 'react-feather';
import { Grid } from "@material-ui/core";
import moment from 'moment'
import 'moment/locale/pt-br';
import API from '../API';
import placeholder from "../assets/profile.png";
import ComentarioEditor from './comentario-editor';
import { AppState } from "store/reducers";
import { connect, ConnectedProps } from "react-redux";
import Modal from '@material-ui/core/Modal';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import ReactHtmlParser from 'react-html-parser';
import Button from '@material-ui/core/Button';
import './comentario.scss';
import { environment } from '../config/environment';
//import { Link } from "react-router-dom";


const mapState = (state: AppState) => ({
	comentariosState: state.comentarios,
	profile: state.profile,
	isLogged: state.isLogged
})
const mapDispatch = {
	addLike: (item: number) => ({type: 'ADD_LIKE', payload: item}),
	removeLike: (item: number) => ({type: 'REMOVE_LIKE', payload: item}),
}
const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
type RouterProps = RouteComponentProps<{}>
type MyProps = PropsFromRedux & WithSnackbarProps & RouterProps & { comentario: any, allowResponse: boolean, onCompartilharComentario? : (comentarioId: number) => void, onResponderComentario?: (comentarioId: number, texto: string) => Promise<boolean | undefined>, onExcluirComentario?: (comentarioId: number) => void, legislacaoId?: number, artigoId?: number};
type MyState = { comentario: any, 
	numLikes: number, responder: boolean, menuOpcoes: boolean, showMore: boolean, 
	edicao: boolean, modalDenuncia: boolean, modalPerfil: boolean, modalPerfilUsuario: any, motivoDenuncia: string, loading: boolean};

class Comentario extends Component<MyProps, MyState> {
  commentThreeshold: number = 500;
  constructor(props: MyProps) {
	  super(props);
	  moment.locale('pt-br');
	  this.state = { 
	  	comentario: this.props.comentario, numLikes: this.props.comentario.curtidas, responder: false, 
	  	menuOpcoes: false, showMore: false, edicao: false, modalDenuncia: false, modalPerfil: false, 
	  	modalPerfilUsuario: undefined, motivoDenuncia: '', loading: false};
	  this.toogleComentarioEditor = this.toogleComentarioEditor.bind(this);
	  this.sendComentario = this.sendComentario.bind(this);
	  this.handleCommentSize = this.handleCommentSize.bind(this);
	  this.showMore = this.showMore.bind(this);
	  this.handleEditarComentario = this.handleEditarComentario.bind(this);
	  this.handleDenunciarComentario = this.handleDenunciarComentario.bind(this);
	  this.handleEnviarDenuncia = this.handleEnviarDenuncia.bind(this);
	  this.handleCloseModalDenuncia = this.handleCloseModalDenuncia.bind(this);
	  this.handleExibirPerfil = this.handleExibirPerfil.bind(this);
	  this.handleCloseModalPerfil = this.handleCloseModalPerfil.bind(this);
	  this.handleClickOutside = this.handleClickOutside.bind(this);
	  this.handleExcluirComentario = this.handleExcluirComentario.bind(this);
	  this.handleResponderComentario = this.handleResponderComentario.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  toogleComentarioEditor(active: boolean) {
	if(this.props.isLogged){
		this.setState({responder: active});
	}
  	else{
		const action = (

		    <Button onClick={() => {this.props.history.push('/login?returnUrl=' + this.props.history.location.pathname + (this.props.history.location.pathname.split('/').length === 3 ? ('/' + this.props.legislacaoId) : ''));}} className="btn-snackbar">Login </Button>

		);
		this.props.enqueueSnackbar('Você precisa estar logado para comentar.',{ action, variant: 'warning' });
  	}
  }

  toogleComentarioOpcoes(active: boolean) {
	  this.setState({menuOpcoes: active});
  }

  handleClickOutside(event: any) {
	const wrapperRef: Element = this.refs['comentario-opcoes-' + this.props.comentario.id] as Element;
	const toggleButtonRef: Element = this.refs['toggle-comentario-opcoes-' + this.props.comentario.id] as Element;
	if (this.state.menuOpcoes && wrapperRef && toggleButtonRef && !toggleButtonRef.contains(event.target) && !wrapperRef.contains(event.target)) {
		this.setState({menuOpcoes: false});
	  }
  }

  handleCommentSize(comment: string) {
  	if(comment.length > this.commentThreeshold && !this.state.showMore)
	  return comment.substring(0, this.commentThreeshold);
	return comment;
  }

  showMore(e: any) {
	e.preventDefault();
	this.setState({showMore: !this.state.showMore});
  }

  async sendComentario(comentario: string) {
	// /comentario/edit/3
	if (this.props.isLogged) {
		try
		{
	        const comentarioId = this.state.comentario.id;
	        var request = await API.put('/comentario/edit/' + comentarioId,
	        {
	            texto: comentario
	        },
	        {
	          headers: {
	            Authorization: `Bearer ${this.props.profile.token}`,
	          },
			});
			this.setState({ comentario: request.data });
			this.props.enqueueSnackbar("Comentário alterado com successo.", { variant: 'success' }) 
		}
		catch(e)
		{
	        if(e.response) {
	          e.response.data.errors.forEach((element: any) => {
	              this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
	          });
	        }
	        else {
	          this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
	        }
		}
    }
	this.handleEditarComentario();
  }

  async likeComentario() {
	// /like/:id
	if(this.props.isLogged)
	{
		const comentarioId = this.state.comentario.id;
		try
		{
			await API.put('/like/' + comentarioId, {},{
				headers: {
					'Authorization': `Bearer ${this.props.profile.token}`
				}
			});
		}
		catch(e)
		{
		    if(e.response) {
		      e.response.data.errors.forEach((element: any) => {
		          this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
		      });
		    }
		    else {
		      this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
		    }
		}


		if(this.props.comentariosState.liked.indexOf(comentarioId) !== -1) {
			this.props.removeLike(comentarioId);
			this.setState({numLikes: this.state.numLikes - 1});
		}
		else {
			this.props.addLike(comentarioId);
			this.setState({numLikes: this.state.numLikes + 1});
		}
		
	}
	else
	{
		const action = (

		    <Button onClick={() => {this.props.history.push('/login?returnUrl=' + this.props.history.location.pathname + (this.props.history.location.pathname.split('/').length === 3 ? ('/' + this.props.legislacaoId) : ''));}} className="btn-snackbar">Login </Button>

		);
		this.props.enqueueSnackbar('Você precisa estar logado para curtir.',{ action, variant: 'warning' });
	}
  }

  async handleEnviarDenuncia() {
  	this.setState({loading: true})
  	
	if (this.props.isLogged) {
		if(this.state.motivoDenuncia !== '')
		{
			try
			{
				await API.post('/denuncia',
				{
					comentarioId: this.props.comentario.id,
					motivo: this.state.motivoDenuncia
				},
				{
				headers: {
					Authorization: `Bearer ${this.props.profile.token}`,
				},
				});
				this.setState({ modalDenuncia: false, motivoDenuncia: '' });
				this.props.enqueueSnackbar('Denuncia submetida à análise.', {variant: 'success'});
			}
			catch(e)
			{
			    if(e.response) {
			      e.response.data.errors.forEach((element: any) => {
			          this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
			      });
			    }
			    else {
			      this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
			    }
			}
		}
		else {
			this.props.enqueueSnackbar('Você deve preencher o motivo de sua denuncia');
		}
    }

    this.setState({loading: false})
  }

	handleEditarComentario () {
		this.setState({ menuOpcoes: false, edicao: !this.state.edicao });
	}

	async handleExcluirComentario () {
		try
		{
			await API.delete(`/comentario/${this.props.comentario.id}`, {
				headers: {
					Authorization: `Bearer ${this.props.profile.token}`,
				},
			});
			this.props.enqueueSnackbar('Comentário excluído.', {variant: 'success'});
			if (this.props.onExcluirComentario)
				this.props.onExcluirComentario(this.props.comentario.id);
		}
		catch(e)
		{
			if(e.response) {
			  e.response.data.errors.forEach((element: any) => {
				  this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
			  });
			}
			else {
			  this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
			}
		}
	}

	handleDenunciarComentario() {
		this.setState({modalDenuncia: true});
	}

	handleCloseModalDenuncia() {
		this.setState({modalDenuncia: false});
	}

	async handleExibirPerfil(userId: number) {
		try
		{
	        var request = await API.get('/profile/' + userId);
			this.setState({modalPerfil: true, modalPerfilUsuario: request.data});
		}
		catch(e)
		{
	        if(e.response) {
	          e.response.data.errors.forEach((element: any) => {
	              this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
	          });
	        }
	        else {
	          this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
	        }
		}
	}

	handleCloseModalPerfil() {
		this.setState({modalPerfil: false});
	}

	async handleResponderComentario(comentario: string) {
		const result = await this.props.onResponderComentario!(this.props.comentario.id, comentario);
		if(result)
			this.toogleComentarioEditor(false);
	}

	transformHtmlOptions = {
		transform: (node: any) => {
			if (node.name === 'a' && node.attribs && node.attribs.href && node.attribs.class === 'mention') {
				const matched = node.attribs.href.match(/^#([0-9]+)$/i);
	
				if (matched && matched[1]) { // userId
					return <a
							href={node.attribs.href}
							onClick={(e) => {                                                            
							  e.preventDefault();                                                            
							  this.handleExibirPerfil(matched[1]);
							}}
							>{node.children[0].data}</a>
				}
			}
		} 
	};

  render() {
	if(	this.state.edicao )
	{
		return (
			<div className="comment">
				<ComentarioEditor edicaoComentario={this.state.comentario} closeEditorClick={this.handleEditarComentario} onSendComentario={(comentario) => this.sendComentario(comentario) }></ComentarioEditor>
			</div>
		);
	} else
	{
		return ( 
			<div className="comment no-border-bottom">
				<div className="comment-header">
					<h3 className="comment-title cursor-pointer word-break" onClick={() => this.handleExibirPerfil(this.props.comentario.user.id)}>
						<div className="wrap-img wrap-img-36" style={{ backgroundImage: "url(" + (this.props.comentario.user.foto ? this.props.comentario.user.foto : placeholder) + ")" }}></div>
							{ this.props.comentario.user.pseudonimo ? this.props.comentario.user.pseudonimo : this.props.comentario.user.nome}
							{ this.props.comentario.user.roles.indexOf('Acreditado') !== -1 ? <span className="comment-title-tag space color-orange">ACREDITADO</span> : '' }
					</h3>
				</div>
				<div className="comment-content text-default color-dark-green">
					{ ReactHtmlParser(this.handleCommentSize(this.state.comentario.texto), this.transformHtmlOptions) }
				</div>
				{ this.props.comentario.texto.length > this.commentThreeshold && !this.state.showMore? <button className="comment-more" onClick={(e) => this.showMore(e)}>Ver mais</button> : '' }
				<div className="comment-footer">
					<Grid container>
						<Grid item sm={12} md={6}>
							<div className={"comment-likes " + (this.props.comentariosState.liked.indexOf(this.props.comentario.id) !== -1 ? 'liked' : '')}>
								<Icon.ThumbsUp onClick={() => this.likeComentario()}/>
								<span className="number">{ this.state.numLikes }</span>
							</div>
							{ this.props.allowResponse ? <span className="color-weak-green" onClick={() => this.toogleComentarioEditor(!this.state.responder) }><span title="Responder" className="cursor-pointer"><Icon.CornerUpLeft/></span></span> : '' }
						</Grid>
						<Grid item sm={12} md={6}>
							<div className="comment-detalhes">
								<div className="float-right">
									<span className="comment-time space">
										{ (this.props.artigoId && this.props.legislacaoId) ? this.state.comentario.codigo
										: 
										this.state.comentario.codigo
										} • { moment.parseZone(this.state.comentario.updatedAt).fromNow(true) }{ this.state.comentario.createdAt !== this.state.comentario.updatedAt ? ' • Editado' : ''}</span>
									<span className="color-weak-green cursor-pointer" ref={('toggle-comentario-opcoes-' + this.props.comentario.id)} onClick={() => this.toogleComentarioOpcoes(!this.state.menuOpcoes)}><Icon.MoreHorizontal /></span>
								</div>
								{ this.state.menuOpcoes ? <div className="comment-opcoes" ref={('comentario-opcoes-' + this.props.comentario.id)}>
								<h4 className="title-normal">Outras ações</h4>
								{ this.props.profile.id === this.props.comentario.user.id && <button className="color-bright-gray text-default" onClick={this.handleEditarComentario}><Icon.Edit2 />Editar comentário</button> }
								{ this.props.profile.id === this.props.comentario.user.id && <button className="color-bright-gray text-default" onClick={this.handleExcluirComentario}><Icon.Trash />Excluir comentário</button> }
								<button className="color-bright-gray text-default" onClick={() => { this.props.onCompartilharComentario!(this.props.comentario.id); this.setState({ menuOpcoes: false}); }}><Icon.Upload />Compartilhar</button>
								{ this.props.profile.id !== this.props.comentario.user.id && <button className="color-bright-gray text-default" onClick={this.handleDenunciarComentario}><Icon.Flag />Reportar comentário</button> }
								</div> : '' }
							</div>
						</Grid>
					</Grid>
				</div>
				{ this.state.responder ? <ComentarioEditor closeEditorClick={() => this.toogleComentarioEditor(false)} onSendComentario={ this.handleResponderComentario }></ComentarioEditor> : '' }
				<Modal
					open={this.state.modalDenuncia}
					onClose={this.handleCloseModalDenuncia}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					className="modal-container">
					<div className="modal-box">
						<Icon.X className="color-clear-gray close-modal" onClick={this.handleCloseModalDenuncia}/>
						<h2 className="title-medium title-modal">Reportar Comentário</h2>

						<textarea maxLength={255} className="input textarea" placeholder="Escreva aqui o motivo" value={this.state.motivoDenuncia} onChange={(e) => this.setState({ motivoDenuncia: e.target.value })}></textarea>
                        <p className="color-bright-gray text-default mt-10">Restam 
                        {
                          this.state.motivoDenuncia ?  <span> {255 - (this.state.motivoDenuncia!.length)} </span>  : <span> 255 </span>
                        }
                        caracteres</p>
						<button className={"btn bg-weak-green borderless color-white btn-modal" + (this.state.loading ? " btn-disabled": "")} onClick={this.handleEnviarDenuncia}>Reportar Comentário</button>
					</div>
				</Modal>

				{
					this.state.modalPerfilUsuario ? (
						<Modal
							open={this.state.modalPerfil}
							onClose={this.handleCloseModalPerfil}
							aria-labelledby="simple-modal-title"
							aria-describedby="simple-modal-description"
							className="modal-container">
							<div className="modal-box modal-small modal-exibir-perfil">
								<Icon.X className="color-clear-gray close-modal" onClick={this.handleCloseModalPerfil}/>

								<Grid container className="bg-white grid p-relative">
									<Grid>
										<div className="wrap-img wrap-img-80" style={{ backgroundImage: "url(" + (this.state.modalPerfilUsuario.foto ? this.state.modalPerfilUsuario.foto : placeholder) + ")" }}></div>
									</Grid>
									<Grid>
										<div className="title-modal-wrapper">
											<h2 className="title-medium title-modal d-block">
												{ this.state.modalPerfilUsuario.pseudonimo ? this.state.modalPerfilUsuario.pseudonimo : this.state.modalPerfilUsuario.nome }
											</h2>
											<p className="text-default color-bright-gray mini-curriculo">
												{ this.state.modalPerfilUsuario.bio }
											</p>
										</div>
									</Grid>
								</Grid>
								{ this.state.modalPerfilUsuario.sobre ? <div className="text-default color-bright-gray pre-line d-block mt-20 mini-curriculo-scroll scroll">{ this.state.modalPerfilUsuario.sobre }</div> : '' }
							</div>
						</Modal>
					) : ""
				}
				
			</div>
		);
	}
  }
}

export default withSnackbar(withRouter(connector(Comentario)))