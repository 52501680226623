import React, { Component } from "react";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import {Helmet} from "react-helmet";

class NotFoundPage extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Página não encontrado - Legispedia</title>
          <meta name="description" content="Uma ferramenta em constante construção, criada para expandir a capacidade dos usuários em lidar com a complexidade do sistema jurídico brasileiro." />
        </Helmet>

       <Container fixed>
        	<Grid container spacing={3}>
        		<div className="mt-60">
      				<h1 className="title">Página não encontrada</h1>
       				<p className="color-bright-gray text-normal">Nenhuma página encontrada</p>
       			</div>
        	</Grid>
        </Container>
      </div>
    );
  }
}
export default NotFoundPage