import React, { Component } from 'react'
import * as Icon from 'react-feather';
import { Box } from '@material-ui/core';
import { List, AutoSizer, CellMeasurerCache, CellMeasurer } from "react-virtualized";

type MyProps = { onChangeSelected: ((itemId?: number) => void), getCurrentIndex:(() => number),  updateCurrentIndex:((index?: any) => void), activeOnly: boolean, legislacao: any[], itemSelectedId?: number, searchResults: any[], searchText: string, searchResultIndex: number, resultIndex: number};
type MyState = { };

// const keepScroll = React.createRef<any>();

export default class LegislacaoCardView extends Component<MyProps, MyState> {

    constructor(props: Readonly<MyProps>) {
        super(props);
        this.state = {
          scrollTop: 0
        };
    }

    startIndex = -1;
    stopIndex = -1;
    scrollAlign = 0;
    
    /**
     * this.scrollToIndex: é utilizado para realizar a navegação na lista. Ele irá se orientar prioritariamente para a busca. 
     * Todavia, se não houver busca ativa, ou seja, um this.props.searchResultIndex > -1, então irá utlizar o this.props.getCurrentIndex();
     */
    scrollToIndex ?= -1;

    cache = new CellMeasurerCache({
        defaultHeight: 50,
        fixedWidth: true
    });

    // Controla o tamanho dos cards quando a janela é redimensionada
    resizeTimeout: any;
    componentDidMount() {
        //this.resizeTimeoutHandler();
        //window.addEventListener("resize", this.resizeTimeoutHandler.bind(this));
    }
    componentWillUnmount() {
        // Sempre que o componente for desmontado eu tenho que atualizar o índice atual no componente pai.
        // Assim, se houver resultado de pesquisa ativo eu o capturo, caso contrário eu pego o startIndex da Lista
        const currentIndex = (this.props.searchResultIndex > -1)? this.props.searchResultIndex:this.startIndex;
        this.props.updateCurrentIndex(currentIndex);
        
        window.removeEventListener("resize", this.resizeTimeoutHandler);
    }

    UNSAFE_componentWillMount(){
        //Aqui eu verifico se existe um indice de resultado ativo antes do primeiro <render>, assim eu o capturo. Caso contrário, eu pego o  <getCurrentIndex>
        this.scrollToIndex = (this.props.searchResultIndex > -1)? this.props.searchResultIndex:this.props.getCurrentIndex();
    }

    UNSAFE_componentWillUpdate(nextProps: any, nextState: any) {
        this.resizeVirtualWindow();

        if(this.props.searchResultIndex > -1){
            // Se houver um resultado ativo será direcionado para result index
            this.scrollToIndex = nextProps.searchResultIndex;
        }else if (this.startIndex !== -1){
            // Se a lista já foi inicializada, ou seja, o estado incial diferente de  -1
            // Assim eu seto o valor de <scrollToIndex> para undefined, assim a lista não sofrerá alteração na posição, mantendo-se na posição atual.
            this.scrollToIndex = undefined;            
        }else{
            // Se não existir um <resultado ativo> ou a lista <está em seu estado inicial, ou seja, monted>: captura o currente index. 
            // Pois pode ocorrer que logo depois do mounted o componente sofra atualização 
            this.scrollToIndex = this.props.getCurrentIndex();          
        }
    }
    
    componentWillReceiveProps() { 
        //this.resizeTimeoutHandler();
        //window.addEventListener("resize", this.resizeTimeoutHandler.bind(this));
    }

    resizeTimeoutHandler() {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(this.resizeVirtualWindow, 200);
    }
    resizeVirtualWindow() {
        this.cache.clearAll();
    }

    handleClick(item: any) {
        let value = item.id;
        if(this.props.itemSelectedId && this.props.itemSelectedId === value)
            value = undefined;

        this.props.onChangeSelected(value);
    }

    handleSearchResult(key: number, defaultText: string) {
        const parts = defaultText.split(new RegExp(`(${this.props.searchText})`, 'gi'));
        return (parts.map((part: any, i: number) => (this.props.searchText !== '' && part.toLowerCase() === this.props.searchText.toLowerCase()) ? <b key={`${key}-${i}`} className="highlight-search">{part}</b> : part));
    }

    renderCard(index: any, isScrolling: any, key: any, parent: any, style: any) {
        const item = this.props.legislacao[index];
        return (
            <CellMeasurer cache={this.cache}
                          columnIndex={0}
                          key={key}
                          parent={parent}
                          rowIndex={index} >
                {({ measure, registerChild }) => (
                    <div key={key} style={style} ref={item.id} className={"nivel-" + item.tipoId + " card-view-nivel " + (item.tipoId < 80 ? 'card-sumario' : '') + " " + (this.props.searchResultIndex === index ? "selected-search-result" : "")}>
                        <Box onClick={() => this.handleClick(item)} className={
                                (this.props.itemSelectedId && this.props.itemSelectedId === item.id ? "selected" : "") + " " +
                                (!item.substituido ? "active" : "expired")
                            } onLoad={measure}>
                            <p><span className="text-slab color-weak-green">{this.handleSearchResult(item.id, item.nome)}</span> {this.handleSearchResult(item.id, item.texto)}</p>
                            { item.tipoId >= 80 ? (
                                <div className="comment-container">
                                    <Icon.MessageCircle /><span className="comments">{item.comentarios}</span>
                                </div>
                            ) : '' }
                        </Box>
                    </div>
                )}
            </CellMeasurer>
        );
      }
    
    render() {
        
        /**
         * _scrollToAlignment: irá definir o layout de posicionamento do scrollToIndex do <List />. A busca utilizou o padrão, que é 'auto'. 
         * Mas como eu oriento a sincronização das listas fora da busca pelo startIndex (Primeiro índice visivel) do método onRowsRendered do <List />, se não houver busca irei utilizar o layout 'start'. 
         * Pois sempre que o componente se desmontar eu atualizo o listCurrentIndex do componente Pai pelo atual startIndex do <List /> do comonponente filho. 
         * Isso acontece no componentWillUnmount().
         * 
         */
        const _scrollToAlignment = (this.props.searchResultIndex > -1)? 'auto': 'start';

        return (
            <div>
                <div className="card-fixed-header" id="fixedHeader"></div>
                <div className="card-view card-container">
                    <AutoSizer>
                        {({ height, width }) => (
                            <List rowCount={this.props.legislacao.length}
                                  height={height}
                                  deferredMeasurementCache={this.cache}
                                  rowHeight={this.cache.rowHeight}
                                  rowRenderer={({ index, isScrolling, key, parent, style }) => this.renderCard(index, isScrolling, key, parent, style)}
                                  width={width}
                                  scrollToIndex={this.scrollToIndex}
                                  scrollToAlignment={_scrollToAlignment}
                                  onRowsRendered={(params) => {
                                    this.startIndex = params.startIndex;
                                    this.stopIndex = params.stopIndex;
                                  }}
                                  onResize={({ height: height, width: width })}
                                  />
                        )}
                    </AutoSizer>

                </div>
            </div>
        )
    }
}
