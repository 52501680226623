import decodeJwt from 'jwt-decode';
import { environment } from 'config/environment';

const authProvider = {
    login: (objLogin: any) =>  {
        const request = new Request(environment.serverUrl + '/login', {
            method: 'POST',
            body: JSON.stringify({ email: objLogin.username, senha: objLogin.password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                const decodedToken: any = decodeJwt(token);
                localStorage.setItem('token', token);
                localStorage.setItem('permissions', decodedToken.permissions);
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    checkError: (error: any) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: async () => {
        const token = localStorage.getItem('token');
        if (token === null)
            return Promise.reject();

        const request = new Request(environment.serverUrl + '/profile', {
            method: 'GET',
            headers: new Headers({'Authorization': `bearer ${token}`}),
        });

        (await fetch(request).then(async result => {
            var data = await result.json();
            if (data.roles && data.roles.indexOf('Administrador') !== -1)
                return Promise.resolve();
            else
                return Promise.reject();
        }, error => {
            return Promise.reject();
        }))
    }, 
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    }
};

export default authProvider;