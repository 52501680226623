import { isLoggedReducer }  from "./isLogged";
import { comentariosReducer } from "./comentarios";
import { userProfileReducer } from "./userProfile";
import { combineReducers } from 'redux';

const rootReducers = combineReducers({
    isLogged: isLoggedReducer,
    comentarios: comentariosReducer,
    profile: userProfileReducer
})

export type AppState = ReturnType<typeof rootReducers>
export default rootReducers;