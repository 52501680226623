import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';


import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { ReferenceField, TextField } from 'react-admin';
// import { Customer, Order } from '../types';

interface Props {
    comentarios?: []
}

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    cost: {
        marginRight: '1em',
        maxWidth: '8em',
        color: theme.palette.text.primary,
    },
    content: {
        maxWidth: '33em',
    },
}));

const ComentariosBox: FC<Props> = ({ comentarios = [] }) => {
    const classes = useStyles();
    // const translate = useTranslate();
    console.log(comentarios)
    return (
        <Card className={classes.root}>
            <CardHeader title={'Últimos comentários'} />
            <List dense={true}>
                {comentarios.map((item: any, index: number) => (
                    <ListItem
                        key={item.id}
                        button
                        component={Link}
                        to={`/users/${item.id}/show`}
                    >
                        <ListItemAvatar>
                            <Avatar
                                src={`${
                                    item.foto
                                }?size=32x32`}
                            />
                        </ListItemAvatar>
                        
                        <ListItemText className={classes.content}
                            primary={<ReferenceField source="userId" record={item} basePath={'/users'} link="show" label="Usuário" reference="users"><TextField source="nome" /></ReferenceField>}
                            secondary={
                            <span>
                                <ReferenceField source="legislacaoId" record={item} basePath={'/legislacao'} label="Breadcrumb" reference="legislacoes-itens" ><TextField source="breadcrumb" /></ReferenceField>
                                <span dangerouslySetInnerHTML={{ __html: item.texto }} />
                            </span>
                        }
                        />
                        <ReferenceField source="legislacaoId" record={item} basePath={'/legislacao'} label="Legislação" reference="legislacoes-itens" ><TextField source="leiPai.nome"  /></ReferenceField>
                        
                        {/* <ReferenceField source="legislacaoId" record={item} basePath={'/'} label="Item" reference="legislacoes-itens" ><TextField source="nome" /></ReferenceField> */}
                    </ListItem>
                ))}
            </List>
        </Card>
    );
};

export default ComentariosBox;