import React, { Component } from "react";
import Comentario from "components/comentario";
import ComentarioEditor from "components/comentario-editor";
import API from "API";
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "store/reducers";
import copy from 'copy-to-clipboard';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import Button from '@material-ui/core/Button';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { environment } from '../../config/environment';
import { Helmet } from "react-helmet";


const mapState = (state: AppState) => ({
  profile: state.profile,
  isLogged: state.isLogged
})
const mapDispatch = { }
const connector = connect(mapState, mapDispatch)

const headerElem = React.createRef<any>();

type PropsFromRedux = ConnectedProps<typeof connector>
type MyProps = PropsFromRedux & RouteComponentProps<{}> & WithSnackbarProps & { lei: any, breadCrumb: string; legislacao: any; leiId?: number, comentarios: any[], itemSelectedId?: string, onNewComment: (comentario: any) => void, onReplyComment: (comentarioId: number, resposta: any) => void, onDeleteComment: (comentarioId: number) => void };
type MyState = { responder: boolean, height: number };

class ComentariosLegislacao extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = { 
      responder: false,
      height: 0,
    };
    this.toogleComentarioEditor = this.toogleComentarioEditor.bind(this);
    this.sendComentario = this.sendComentario.bind(this);
    this.handleCompartilharComentario = this.handleCompartilharComentario.bind(this);
    this.handleRespostaComentario = this.handleRespostaComentario.bind(this);

    if(!this.props.legislacao){
      this.props.history.push('/404')
    }
  }

  filterComentarios() {
    var result = [];
    if (this.props.comentarios !== undefined) result = this.props.comentarios;

    return result;
  }

  toogleComentarioEditor(active: boolean) {
    if(this.props.isLogged)
      this.setState({ responder: active });
    else {
      const action = (

            <Button onClick={() => {this.props.history.push('/login?returnUrl=' + this.props.history.location.pathname + '/' + this.props.legislacao.id);}} className="btn-snackbar">Login </Button>

      );
      this.props.enqueueSnackbar('Você precisa estar logado para comentar.',{ action, variant: 'warning' });
    }
    
  }

  async sendComentario(comentario: string) {
    if (this.props.isLogged) {
      try {
        const legislacaoId = this.props.legislacao.id;
        var request = await API.post("/comentario",
        {
            legislacaoId: legislacaoId,
            texto: comentario
        },
        {
          headers: {
            Authorization: `Bearer ${this.props.profile.token}`,
          },
        });
        this.props.onNewComment(request.data);
        this.setState({ responder: false });
        this.props.enqueueSnackbar("Comentário publicado com sucesso", { variant: 'success' }) 
      } catch(e) {
        if(e.response) {
          e.response.data.errors.forEach((element: any) => {
              this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
          });
        }
        else {
          this.props.enqueueSnackbar(environment.connectionErrorMsg, { variant: 'error' })
        }
      }
        
    }
  }

  async handleRespostaComentario(comentarioId: number, texto: string) : Promise<boolean | undefined> {
    if (this.props.isLogged) {
      try {
        var request = await API.post("/comentario", 
        {
        origemId: comentarioId,
        texto: texto
        },
          {
            headers: {
              Authorization: `Bearer ${this.props.profile.token}`,
            },
        });
        await this.props.onReplyComment(comentarioId, request.data);
        this.setState({ responder: false });
        return true
      } catch(e) {
        e.response.data.errors.forEach((element: any) => {
          this.props.enqueueSnackbar(element.msg, { variant: 'error' }) 
        });
        return false
      }
    }
  }

  // Função que faz o scroll até o elemento quando a tela carrega
  componentDidMount () {
    if (this.props.itemSelectedId)
    {
      let el: Element = this.refs[this.props.itemSelectedId] as Element;
      el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
      });
    }

    const height = headerElem.current.clientHeight + 58;
    this.setState({ height: height }); 
  }

  componentDidUpdate(){

    const height = headerElem.current.clientHeight + 58;
    
    if (this.state.height !== height) {
      this.setState({ height: height }); 
    }
  }
  
  handleCompartilharComentario(comentarioId: number) {
		copy(window.location.origin + '/legislacao' + (this.props.leiId ? ('/' + this.props.leiId) : '') + '/' + this.props.legislacao.id + '/' + comentarioId);
		this.props.enqueueSnackbar('Link copiado!', { variant: 'success'});
	}

  render() {
      return (
      this.props.legislacao ? 
        <div
          className={
            "scroll comentarios-container " +
            (this.state.responder ? "comment-open" : "")
          }
        >
        <Helmet>
          <title>{this.props.breadCrumb} - {this.props.lei.nome} - Legislação - Legispedia</title>
          <meta name="description" content={this.props.lei.texto} />
        </Helmet>

          <div className="header-breadcrumb">{this.props.breadCrumb}</div>
          <div className={"legislacao nivel-" + this.props.legislacao.tipoId} ref={headerElem}>
            <span className={"text-slab color-orange " + (this.props.legislacao.substituido ? 'expired' : '')}>
              {this.props.legislacao.nome}
            </span>
            <p className={(this.props.legislacao.substituido ? 'expired' : '')}>{this.props.legislacao?.texto}</p>
            { this.props.legislacao.tipoId >= 80 ? ( 
              <button
                className="text-default btn btn-outline"
                onClick={() => this.toogleComentarioEditor(!this.state.responder)} >
                Comentar
              </button>
            ) : '' }
            {this.state.responder ? (
              <ComentarioEditor
                closeEditorClick={() => this.toogleComentarioEditor(false)}
                onSendComentario={(comentario) => this.sendComentario(comentario)}
              ></ComentarioEditor>
            ) : (
              ""
            )}
          </div>
          <div className="comentarios scroll" style={{top: this.state.height}}>
            {this.filterComentarios().map((item: any) => (
              <div key={item.id} ref={item.id} className="comentario-item-container">
                <Comentario comentario={item} allowResponse={true} onCompartilharComentario={this.handleCompartilharComentario} onExcluirComentario={this.props.onDeleteComment} legislacaoId={this.props.legislacao.id} onResponderComentario={this.handleRespostaComentario}></Comentario>
                <div className="respostas">
                  {item.respostas.map((resposta: any) => (
                    <div key={resposta.id} ref={resposta.id}>
                    <Comentario comentario={resposta} allowResponse={false} onCompartilharComentario={this.handleCompartilharComentario} onExcluirComentario={this.props.onDeleteComment} legislacaoId={this.props.legislacao.id}></Comentario>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
       :  ""
      );

  }
}
export default withRouter(withSnackbar(connector(ComentariosLegislacao)));